import React, { useState, useEffect } from 'react';
import InputText from '../../components/InputText';
import InputLookUp from '../../components/InputLookUp';
import Button from '../../components/Button';
import Api from '../../api/Api';
import { Picker } from 'react-native';
import { TabView, Tab } from '../../components/Tabs';
import { View, Text, StyleSheet } from 'react-native-web';
import { createBrowserHistory } from 'history';
import { useRouteMatch } from 'react-router-dom';
import SubHeader from '../../components/SubHeader';

let history = createBrowserHistory();

export default function AddSitesForm(props) {
  const [name, setName] = useState();
  const [power, setPower] = useState();
  const [category, setCategory] = useState();
  const [client, setClient] = useState();
  const [company, setCompany] = useState();
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [notes, setNotes] = useState();
  const [monitoringDate, setMonitoringDate] = useState();
  const [inititialProductionRecord, setInitialProducationRecord] = useState();
  const [provisionNumber, setProvisionNumber] = useState();
  const [gaugeNumber, setGaugeNumber] = useState();
  const [contract, setContract] = useState();

  const [viewMode, setViewMode] = useState('view');

  let viewPath = useRouteMatch({
    path: '/admin/sites/:id',
    exact: true,
  });

  let editPath = useRouteMatch({
    path: '/admin/sites/:id/edit',
    exact: true,
  });
  let addPath = useRouteMatch({
    path: '/admin/sites/add',
    exact: true,
  });
  useEffect(() => {
    if (addPath) {
      setViewMode('add');
    } else if (editPath) {
      setViewMode('edit');
    } else if (viewPath) {
      setViewMode('view');
    }
  }, [addPath, editPath, viewPath]);

  let isView = viewMode === 'view';

  function addSites() {
    Api.post('/admin/sites', {
      name: name,
      power: power,
      site_category_id: category.id,
      client_id: client.id,
      company_id: company.id,
      description: description,
      location: location,
      lat: lat,
      lng: lng,
    }).then((res) => {
      history.goBack();
    });
  }

  function editSite() {
    Api.put('/admin/sites/' + props.match.params.id, {
      name: name,
      power: power,
      site_category_id: category,
      client_id: client.id,
      company_id: company.id,
      description: description,
      location: location,
      lat: lat,
      lng: lng,
    }).then((res) => {
      history.goBack();
    });
  }

  useEffect(() => {
    const fetchSite = async () => {
      const res = await Api.get('/admin/sites/' + props.match.params.id);
      let data = res.data.data;
      setName(data.name);
      setCategory(data.site_category_id);
      setClient(data.client);
      setPower(data.power);
      setCompany(data.company);
      setLocation(data.location);
      setLat(data.lat);
      setLng(data.lng);
      setDescription(data.description);
    };
    if (props.match.params.id) {
      fetchSite();
    }
  }, [props.match.params.id, viewMode]);

  function clearState() {
    setName('');
    setPower('');
    setCategory(0);
    setClient();
    setCompany();
    setDescription();
    setLocation();
    setLat();
    setLng();
  }

  function renderButtons() {
    if (viewMode === 'view') {
      return null;
    }
    if (viewMode === 'add') {
      return (
        <View style={styles.buttonForm}>
          <Button
            onPress={() => addSites()}
            style={styles.button}
            title={'Save'}
          />
          <Button
            onPress={() => clearState()}
            style={styles.button}
            title={'Cancel'}
          />
        </View>
      );
    } else {
      return (
        <View style={styles.buttonForm}>
          <Button
            onPress={() => editSite()}
            style={styles.button}
            title={'Save'}
          />
          <Button
            onPress={() => clearState()}
            style={styles.button}
            title={'Cancel'}
          />
        </View>
      );
    }
  }

  const chooseSubHeader = () => {
    if (viewMode === 'view') {
      return <SubHeader title="Εγκατάσταση" />;
    } else if (viewMode === 'add') {
      return <SubHeader title="Προσθέστε Εγκατάσταση" />;
    } else if (viewMode === 'edit') {
      return <SubHeader title="Επεξεργασία Εγκατάστασης" />;
    }
  };

  const displayCategory = () => {
    switch (category) {
      case 1:
        return (
          <View>
            <Text>Κατηγορία</Text>
            <Text>Φ/Β ΣΤΕΓΗ</Text>
          </View>
        );
      case 2:
        return (
          <View>
            <Text>Κατηγορία</Text>
            <Text>Φ/Β ΑΥΤΟΝΟΜΟ</Text>
          </View>
        );
      case 3:
        return (
          <View>
            <Text>Κατηγορία</Text>
            <Text>Φ/Β ΑΓΡΟΤΙΚΟ</Text>
          </View>
        );
      case 4:
        return (
          <View>
            <Text>Κατηγορία</Text>
            <Text>Φ/Β ΚΤΗΡΙΟ</Text>
          </View>
        );
      case 5:
        return (
          <View>
            <Text>Κατηγορία</Text>
            <Text>Φ/Β ΠΑΡΚΟ</Text>
          </View>
        );
      default:
        return;
    }
  };

  return (
    <View style={styles.container}>
      {chooseSubHeader()}
      <InputText
        viewMode={isView}
        displayTitle="Όνομα"
        style={styles.inputText}
        value={name}
        onChangeText={(e) => setName(e)}
        multiline={true}
        placeholder="Add name"
      />
      <InputText
        viewMode={isView}
        displayTitle="Ισχύς"
        style={[styles.inputText, { marginLeft: 10, marginBottom: 15 }]}
        value={power}
        onChangeText={(e) => setPower(e)}
        multiline={true}
        placeholder="Add power"
      />

      <TabView>
        <Tab title="Κύρια Στοιχεία">
          {viewMode === 'add' ? (
            <View style={styles.pickerView}>
              <Text style={styles.categoryText}>Κατηγορία</Text>

              <Picker
                style={styles.picker}
                onValueChange={(itemValue, itemIndex) => setCategory(itemValue)}
              >
                <Picker.Item label="Please Select" value="0" />
                <Picker.Item label="Φ/Β ΣΤΕΓΗ" value="1" />
                <Picker.Item label="Φ/Β ΑΥΤΟΝΟΜΟ" value="2" />
                <Picker.Item label="Φ/Β ΑΓΡΟΤΙΚΟ" value="3" />
                <Picker.Item label="Φ/Β ΚΤΗΡΙΟ" value="4" />
                <Picker.Item label="Φ/Β ΠΑΡΚΟ" value="5" />
              </Picker>
            </View>
          ) : (
            displayCategory()
          )}

          <InputLookUp
            viewMode={isView}
            displayTitle="Πελάτης"
            displayKey="client"
            endpoint="admin/client"
            searchQueryKey="search"
            onSelect={(item) => setClient(item)}
            value={client}
          >
            {client ? client.client : ''}
          </InputLookUp>

          <InputLookUp
            viewMode={isView}
            displayTitle="Εταιρία"
            displayKey="name"
            endpoint="admin/companies"
            searchQueryKey="search"
            onSelect={(item) => setCompany(item)}
            value={company}
          >
            {company ? company.name : ''}
          </InputLookUp>

          <InputText
            viewMode={isView}
            displayTitle="Περιγραφή"
            value={description}
            placeholder="Περιγραφή"
            onChangeText={(e) => setDescription(e)}
          />
          <InputText
            viewMode={isView}
            displayTitle="Αρχική Καταγραφή Παραγωγής"
            value={inititialProductionRecord}
            placeholder="Αρχική Καταγραφή Παραγωγής"
            onChangeText={(e) => setInitialProducationRecord(e)}
          />
          <InputText
            viewMode={isView}
            displayTitle="Αριθμός Παροχής"
            value={provisionNumber}
            placeholder="Αριθμός Παροχής"
            onChangeText={(e) => setProvisionNumber(e)}
          />
          <InputText
            viewMode={isView}
            displayTitle="Αριθμός Μετρητή"
            value={gaugeNumber}
            placeholder="Αριθμός Μετρητή"
            onChangeText={(e) => setGaugeNumber(e)}
          />
          <InputText
            viewMode={isView}
            displayTitle="Συμβόλαιο"
            value={contract}
            placeholder="Συμβόλαιο"
            onChangeText={(e) => setContract(e)}
          />
          <InputText
            viewMode={isView}
            displayTitle="Σημειώσεις"
            value={notes}
            placeholder="Σημειώσεις"
            onChangeText={(e) => setNotes(e)}
          />
        </Tab>

        <Tab title="Γεωγραφικά Δεδομένα">
          <InputText
            viewMode={isView}
            displayTitle="Τοποθεσία"
            value={location}
            placeholder="Τοποθεσία"
            onChangeText={(e) => setLocation(e)}
          />
          <InputText
            viewMode={isView}
            displayTitle="Γεωγρ. Πλάτος"
            value={lat}
            placeholder="Γεωγρ. Πλάτος"
            onChangeText={(e) => setLat(e)}
          />
          <InputText
            viewMode={isView}
            displayTitle="Γεωγρ. Μήκος"
            value={lng}
            placeholder="Γεωγρ. Μήκος"
            onChangeText={(e) => setLng(e)}
          />
        </Tab>
      </TabView>

      <View style={[styles.container, { marginTop: 40 }]}>
        <TabView>
          <Tab title="Προσβάσεις" />
          <Tab title="Χαρακτηριστικά" />
          <Tab title="Εξοπλισμός" />
          <Tab title="Συμβόλαια" />
          <Tab title="Υπηρεσίες" />
        </TabView>
      </View>
      {renderButtons()}
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '20px',
  },

  firstText: {
    fontSize: 20,
    borderBottomColor: 'blue',
    borderBottomWidth: 0.5,
    borderStyle: 'dotted',
    paddingRight: 180,
  },

  inputText: {
    width: 280,
    height: 80,
    borderRadius: 15,
    margin: 0,
    borderWidth: 0.5,
    borderColor: 'black',
    padding: 0,
    marginTop: 15,
  },

  picker: {
    width: 200,
    height: 30,
    margin: 15,
    marginLeft: 200,
  },

  buttonForm: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },

  button: {
    width: 120,
    borderRadius: 5,
    alignSelf: 'flex-end',
    marginLeft: 15,
    marginTop: 25,
  },

  pickerView: { flex: 1, flexDirection: 'row' },

  categoryText: { marginTop: 17, fontSize: 16 },
});
