import React, { Component } from 'react';
import { View, Text, FlatList, StyleSheet, ScrollView } from 'react-native-web';
import * as notificationActions from '../redux/actions/notifications';
import { connect } from 'react-redux';
import Button from '../components/Button';
import Modal from 'modal-enhanced-react-native-web';
import CardBlock from '../components/CardBlock';

class Notifications extends Component {
  constructor() {
    super();
    this.state = {
      visibleModal: false,
    };
  }

  componentDidMount() {
    this.props.fetchNotifications(0);
  }

  renderNotifications = (item) => {
    return (
      <View style={styles.container}>
        <CardBlock>
          <View>
            <Text>Πελάτης</Text>
            <Text style={[styles.text, styles.textWeight]}>{item.client}</Text>
          </View>
          <View>
            <Text>Τίτλος</Text>
            <Text style={[styles.text, styles.textWeight]}>{item.title}</Text>
          </View>
          <View>
            <Text>Μήνυμα</Text>
            <Text style={styles.text}>{item.body}</Text>
          </View>
        </CardBlock>
      </View>
    );
  };

  renderNotificationsList = () => {
    return (
      <View>
        <FlatList
          data={this.props.notifications}
          renderItem={({ item }) => this.renderNotifications(item)}
          onEndReached={this.loadMoreNotifications}
          contentContainerStyle={styles.modalContent}
        />
      </View>
    );
  };

  loadMoreNotifications = () => {
    this.props.fetchNotifications();
  };

  render() {
    return (
      <View>
        <Button
          title="Modal"
          onPress={() => this.setState({ visibleModal: true })}
        />

        <Modal
          onBackdropPress={() => this.setState({ visibleModal: false })}
          isVisible={this.state.visibleModal}
          animationIn="slideInLeft"
          animationOut="slideOutRight"
        >
          <ScrollView style={{ height: 300 }}>
            <CardBlock displayTitle="Your notifications">
              {this.renderNotificationsList()}
            </CardBlock>
          </ScrollView>
        </Modal>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.notificationsReducer });

export default connect(mapStateToProps, notificationActions)(Notifications);

const styles = StyleSheet.create({
  container: { flex: 1, flexDirection: 'row', margin: 10 },
  text: { fontFamily: 'Roboto', margin: 10 },
  textWeight: { fontWeight: 'bold' },
  modalContent: {
    flex: 1,
    backgroundColor: '#F6F6F6',
    padding: 5,
    height: 150,
    justifyContent: 'space-between',
    alignItems: 'flexStart',
    borderRadius: 4,
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
});
