import React, { Component } from 'react';
import { View, Text } from 'react-native-web';
import CardBlock from '../../components/CardBlock';
import { GridLayout, Row, Col } from '../../components/Grid';
import WorkTasksForm from '../../components/WorkTasksForm';

class adminAnalytics extends Component {
  state = {};
  render() {
    return (
      <View>
        <View>
          <GridLayout>
            <Row>
              <Col lg={6} md={12}>
                <CardBlock displayTitle="SITES">
                  <Text>reaasdasd</Text>
                  <View style={{ height: 200 }} />
                </CardBlock>
              </Col>
              <Col lg={6} md={12}>
                <CardBlock displayTitle="SITES">
                  <Text>reaasdasd</Text>
                  <View style={{ height: 200 }} />
                </CardBlock>
              </Col>
            </Row>
          </GridLayout>
        </View>
        <WorkTasksForm />
      </View>
    );
  }
}

export default adminAnalytics;
