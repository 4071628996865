import axios from "axios";
import cookie from "react-cookies";

const instance = axios.create({
  baseURL: "http://api.pvsoft.distemicha.online/",
  headers: { Token: cookie.load("token") },
});

instance.interceptors.response.use(
  function (response) {
    if (
      response.data.status === "error" &&
      response.data.error === "login_required"
    ) {
      window.location.replace("/login");
      return null;
    }
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      window.location.replace("/login");
      return null;
    }
    return Promise.reject(error);
  }
);

export default instance;
