import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Picker,
} from 'react-native-web';
import Api from '../../api/Api';
import Table from '../../components/Table';
export default class Works extends React.Component {
  state = {
    works: [],
    filteredWorks: [],
    searchInput: '',
    sitesPickerValue: null,
    sites: [],
  };

  fetchWorks = () => {
    Api.get('client/works', {
      params: {
        search: this.state.searchInput,
        site_id: this.state.sitesPickerValue,
      },
    })
      .then((res) => {
        const data = res.data.data;
        console.log(data);
        this.setState({ works: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchSites = () => {
    Api.get('client/sites').then((res) => {
      const data = res.data.data;
      this.setState({ sites: data });
      console.log(data);
    });
  };

  searchTimeout;
  filterWorks = (text) => {
    this.setState({ input: text });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => this.fetchWorks(), 1000);
  };

  componentDidMount() {
    this.fetchWorks();
    this.fetchSites();
  }

  renderWorks = (item) => {
    return (
      <View style={styles.container}>
        <TouchableOpacity
          onPress={() => this.props.history.push(`/works/${item.id}`)}
          style={styles.view2}
        >
          <Text style={styles.text}>{item.id}</Text>
          <Text style={styles.text}>{item.service_tasks_template_id}</Text>
          <Text style={styles.text}>{item.client_id}</Text>
          <Text style={styles.text}>{item.company_id}</Text>
          <Text style={styles.text}>{item.admin_comment}</Text>
        </TouchableOpacity>
      </View>
    );
  };

  render() {
    console.log(this.state.input);
    console.log(this.state.pickerValue);
    return (
      <View style={styles.container}>
        <TextInput
          style={styles.inputAndPickerStyle}
          placeholder="search by name"
          value={this.state.searchInput}
          onChangeText={this.filterWorks}
          autoCapitalize="sentences"
        />

        <Picker
          style={styles.inputAndPickerStyle}
          selectedValue={this.state.pickerValue}
          onValueChange={(itemValue, itemIndex) => {
            this.setState({ sitesPickerValue: itemValue }, () => {
              this.fetchWorks();
            });
          }}
        >
          {this.state.sites.map((item) => (
            <Picker.Item label={item.name} value={item.id} />
          ))}
        </Picker>

        <Table
          dataMap={{ id: 'Work Id', site_id: 'Name', admin_comment: 'Comment' }}
          data={this.state.works}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },

  view: {
    flexDirection: 'row',
    backgroundColor: '#eeeeee',
    margin: '5px',
    padding: '25px',
  },
  view2: {
    flexDirection: 'row',
    backgroundColor: '#bdbdbd',
    margin: '5px',
    padding: '15px',
  },

  text: {
    flex: 1,
    fontWeight: 'bold',
    justifyContent: 'space-between',
  },

  inputAndPickerStyle: { width: 150, height: 47 },
});
