import React from 'react';
import { View, Text, StyleSheet } from 'react-native-web';

export default function SubHeader(props) {
  return (
    <View style={styles.header}>
      <Text style={styles.firstText}>{props.title}</Text>
      <Text style={styles.secondText}>{props.title}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '20px',
  },

  firstText: {
    fontSize: 20,
    letterSpacing: 1,
    alignSelf: 'center',
    fontFamily: 'Roboto',
    paddingBottom: 15,
    fontWeight: 20,
  },
});
