import React from 'react';
import { View, useWindowDimensions } from 'react-native-web';
import EStyleSheet from 'react-native-extended-stylesheet';

export function GridLayout(props) {
  return <View style={styles.layout}>{props.children}</View>;
}

export function Row(props) {
  return <View style={styles.row}>{props.children}</View>;
}

export function Col(props) {
  const window = useWindowDimensions();
  const sizes = [
    { size: 'xs', min: 0, max: 575, value: props.xs },
    { size: 'sm', min: 576, max: 767, value: props.sm },
    { size: 'md', min: 768, max: 991, value: props.md },
    { size: 'lg', min: 992, max: 1199, value: props.lg },
    { size: 'xl', min: 1200, max: Infinity, value: props.xl },
  ];
  let currentSize = { value: 12 };
  for (let item of sizes) {
    if (item.value && item.max < window.width) {
      currentSize = item;
    }
    if (item.value && item.max > window.width && item.min <= window.width) {
      currentSize = item;
      break;
    }
  }

  return (
    <View
      style={[styles.col, { flexBasis: (100 / 12) * currentSize.value + '%' }]}
    >
      {props.children}
    </View>
  );
}

const styles = EStyleSheet.create({
  layout: {},

  row: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
  },

  col: {
    // flexBasis: '100%',
  },
});
