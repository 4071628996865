import React from 'react';
import { View, StyleSheet } from 'react-native';
import { LineChart, AreaChart, BarChart } from 'react-charts-d3';
import Pickers from '../../components/Pickers';

function randomNumber() {
  return Math.random() * (40 - 0) + 0;
}

const data = [
  {
    key: 'Group 1',
    values: [
      { x: 'A', y: randomNumber() },
      { x: 'B', y: randomNumber() },
      { x: 'C', y: randomNumber() },
      { x: 'D', y: randomNumber() },
    ],
  },
  {
    key: 'Group 2',
    values: [
      { x: 'A', y: randomNumber() },
      { x: 'B', y: randomNumber() },
      { x: 'C', y: randomNumber() },
      { x: 'D', y: randomNumber() },
    ],
  },
  {
    key: 'Group 3',
    values: [
      { x: 'A', y: randomNumber() },
      { x: 'B', y: randomNumber() },
      { x: 'C', y: randomNumber() },
      { x: 'D', y: randomNumber() },
    ],
  },
];

const data1 = [
  {
    key: 'Group 1',
    values: [
      { x: 'A', y: randomNumber() },
      { x: 'B', y: randomNumber() },
      { x: 'C', y: randomNumber() },
      { x: 'D', y: randomNumber() },
    ],
  },
  {
    key: 'Group 2',
    values: [
      { x: 'A', y: randomNumber() },
      { x: 'B', y: randomNumber() },
      { x: 'C', y: randomNumber() },
      { x: 'D', y: randomNumber() },
    ],
  },
  {
    key: 'Group 3',
    values: [
      { x: 'A', y: randomNumber() },
      { x: 'B', y: randomNumber() },
      { x: 'C', y: randomNumber() },
      { x: 'D', y: randomNumber() },
    ],
  },
];

const data3 = [
  {
    key: 'Group 1',
    values: [
      { x: 'A', y: randomNumber() },
      { x: 'B', y: randomNumber() },
      { x: 'C', y: randomNumber() },
      { x: 'D', y: randomNumber() },
    ],
  },
  {
    key: 'Group 2',
    values: [
      { x: 'A', y: randomNumber() },
      { x: 'B', y: randomNumber() },
      { x: 'C', y: randomNumber() },
      { x: 'D', y: randomNumber() },
    ],
  },
  {
    key: 'Group 3',
    values: [
      { x: 'A', y: randomNumber() },
      { x: 'B', y: randomNumber() },
      { x: 'C', y: randomNumber() },
      { x: 'D', y: randomNumber() },
    ],
  },
];

export default () => {
  return (
    <View style={styles.scrollContainer}>
      <View style={styles.container}>
        <Pickers />
        <View style={styles.row}>
          <View style={styles.column}>
            <View style={styles.box}>
              <LineChart data={data} />
            </View>
            <View style={styles.box}>
              <LineChart data={data} />
            </View>
          </View>
          <View style={styles.column}>
            <View style={styles.box}>
              <AreaChart data={data1} />
            </View>
            <View style={styles.box}>
              <BarChart data={data3} />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  scrollContainer: {
    flex: 1,
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: 2,
    backgroundColor: 'white',
    marginTop: 2,
  },
  container: {
    flex: 1,
    margin: 15,
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    fleWrap: 'wrap',
    width: '100%',
  },
  column: {
    flex: 1,
    flexDirection: 'column',
  },

  box: {
    width: '100%',
    alignSelf: 'start',
    padding: 50,
  },
});
