import React from 'react';
import { View, StyleSheet, TouchableOpacity, Image, Text } from 'react-native';
import { useHistory } from 'react-router-dom';

export default ({ name, power, id }) => {
  let history = useHistory();

  return (
    <TouchableOpacity onPress={() => history.push(`/sites/${id}`)}>
      <View style={styles.body}>
        <Image
          style={styles.image}
          source={require('../assets/images/PVplant.jpg')}
        />
        <View style={styles.title}>
          <Text>{name}</Text>
        </View>
        <View style={styles.footer}>
          <Text>{power + 'KW'}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  body: {
    flex: 1,
    backgroundColor: 'white',
    height: 100,
    width: '100%',
    paddingVertical: 15,
    borderWidth: 1,
    margin: 15,
    padding: 15,
  },
  title: {
    color: 'black',
    textDecorationLine: 'underline',
  },

  footer: {
    color: 'black',
  },

  image: {
    width: 100,
    height: 107,
    alignSelf: 'flex-start',
  },
});
