import {
  FETCH_SITES_FAILURE,
  FETCH_SITES_REQUEST,
  FETCH_SITES_SUCCESS,
} from '../types/sites';

const initialState = {
  loading: false,
  sites: [],
  error: '',
};

export const sitesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SITES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SITES_SUCCESS:
      return {
        loading: false,
        sites: action.payload,
        error: '',
      };
    case FETCH_SITES_FAILURE:
      return {
        loading: false,
        sites: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
