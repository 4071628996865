import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native-web';
import Api from '../../api/Api';
import Table from '../../components/Table';
import Button from '../../components/Button';
import SubHeader from '../../components/SubHeader';
import InputText from '../../components/InputText';
import InputLookUp from '../../components/InputLookUp';
import Filters from '../../components/Filters';

class AdminClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      searchInput: '',
      filteredClients: [],
      client: [],
      agents: [],
      filteredAgents: [],
    };
  }

  fetchClients = ({ agent_id, client_id, text } = {}) => {
    Api.get('/admin/client', {
      params: {
        search: text,
        agent_id: agent_id,
      },
    })
      .then((res) => {
        let data = res.data.data;
        this.setState({ data: data });
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchAgents = () => {
    Api.get('/admin/agents')
      .then((res) => {
        let data = res.data.data;
        this.setState({ agents: data });
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteClient = (id) => {
    Api.delete('/admin/client/ ' + id).then((res) => {
      console.log('deleted item with id' + id);
    });
  };

  searchTimeout;
  filterClients = (text) => {
    this.setState({
      searchInput: text,
      filteredClients: this.state.data.filter((item) =>
        item.client.includes(text)
      ),
    });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => this.fetchClients(), 1000);
  };

  componentDidMount() {
    this.fetchClients();
    this.fetchAgents();
  }

  searchTimeout;
  filterAgents = (key) => {
    this.setState({
      filteredAgents: this.state.agents.filter((item) => {
        item.agent_id.includes(key);
      }),
    });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => this.fetchAgents(), 1000);
  };

  render() {
    let filteredClients =
      !this.state.searchInput && this.state.filteredClients.length === 0
        ? this.state.data
        : this.state.filteredClients;

    let filteredAgents =
      !this.state.filteredAgents && this.state.agents.length === 0
        ? this.state.agents
        : this.state.filteredAgents;

    this.state.data.map((item) => {
      return (item.button = (
        <Button
          style={{ width: '50%' }}
          onPress={() => this.props.history.push(`/admin/clients/${item.id}`)}
          title="View"
        />
      ));
    });

    this.state.data.map((item) => {
      return (item.editButton = (
        <Button
          style={{ width: '50%' }}
          onPress={() => this.props.history.push(`clients/${item.id}/edit`)}
          title="Edit"
        />
      ));
    });

    this.state.data.map((item) => {
      return (item.deleteButton = (
        <Button
          style={{ width: '50%' }}
          onPress={() => this.deleteClient(item.id)}
          title="Delete"
        />
      ));
    });

    return (
      <View style={styles.container}>
        <SubHeader title="Πελάτες" />
        <Filters
          displayTitle="Εταιρία"
          onChangeFilters={(newFilters) => {
            console.log(newFilters);
            this.fetchClients({ agent_id: newFilters.agent.agent_id });
          }}
          fields={[
            {
              type: 'InputLookUp',
              key: 'agent',
              lookupProps: {
                displayKey: 'agent',
                displayTitle: 'Πάροχος',
                endpoint: 'admin/agents',
                searchQueryKey: 'search',
              },
            },
          ]}
        />

        <InputText
          placeholder="search"
          onChangeText={this.filterClients}
          value={this.state.searchInput}
          displayTitle="Αναζήτηση Πελάτη"
        />

        <Button
          onPress={() => this.props.history.push('/admin/clients/add')}
          style={styles.button}
        >
          <Text>Add New client</Text>
        </Button>
        <Table
          dataMap={{
            client: 'Πελάτης',
            agent: 'Πάροχος',
            status: 'Κατάσταση',
            address: 'Διεύθυνση',
            city: 'Πόλη',
            country: 'Χώρα',
            button: '',
            editButton: '',
            deleteButton: '',
          }}
          data={filteredClients}
          rowStyle={styles.rowStyle}
        />
      </View>
    );
  }
}

export default AdminClients;

const styles = StyleSheet.create({
  container: {
    // flex: 1,
  },
  clientsContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '20px',
  },
  textColor: {
    color: 'blue',
  },
  fontSize: {
    fontSize: '30px',
  },
  textUnderline: {
    borderBottomColor: 'blue',
    borderBottomWidth: 0.5,
    paddingRight: 180,
    borderStyle: 'dotted',
  },

  button: {
    width: 180,
    marginTop: 15,
  },

  rowStyle: {
    backgroundColor: '#a4bfeb',
    marginBottom: 15,
  },
});
