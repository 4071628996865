import React, { useState, useEffect } from 'react';
import { Picker, View, StyleSheet } from 'react-native-web';
import DatePicker from './Datepicker.js';
import Api from '../api/Api';

export default () => {
  const [data, setData] = useState([]);
  const [selectedSite, setSelectedSite] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const result = await Api.get('client/sites');
      setData(result.data.data);
      console.log(result.data.data);
    };
    fetchData();
  }, []);

  return (
    <View style={{ zIndex: 1 }}>
      <View style={styles.container}>
        <Picker
          style={styles.pickerDimensions}
          selectedValue={selectedSite}
          onValueChange={(itemValue, itemIndex) => setSelectedSite(itemValue)}
        >
          {data.map((item) => (
            <Picker.Item label={item.name} value={item.value} />
          ))}
        </Picker>
        <DatePicker />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'flex-end',
    margin: 5,
  },

  pickerDimensions: { width: 150, height: 47 },

  
});
