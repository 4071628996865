import React from 'react';
import { View, Text } from 'react-native-web';
import EStyleSheet from 'react-native-extended-stylesheet';

export default function CardBlock(props) {
  return (
    <View style={styles.container}>
      <View style={styles.cardContent}>
        <Text style={[styles.title, props.titleStyle]}>
          {props.displayTitle}
        </Text>
        <View style={[styles.content]}>{props.children}</View>
      </View>
    </View>
  );
}

const styles = EStyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    borderRadius: 6,
    elevation: 3,
    shadowOffset: { width: 1, height: 1 },
    shadowColor: '#333',
    shadowOpacity: 0.5,
    shadowRadius: 2,
    marginHorizontal: 5,
    marginVertical: 7,
    border: '1px solid gray',
    backgroundColor: '#F6F6F6',
  },

  cardContent: {
    marginHorizontal: 18,
    marginVertical: 20,
  },

  content: {
    justifyContent: 'center',
    marginLeft: 3,
  },

  title: {
    fontSize: 16,
    letterSpacing: 2,
    alignSelf: 'center',
    fontFamily: 'Roboto',
    paddingBottom: 15,
    fontWeight: 'bold',
  },
});
