import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native-web';
import Api from '../../api/Api';
import { createBrowserHistory } from 'history';
import InputText from '../../components/InputText';
import InputLookUp from '../../components/InputLookUp';
import Button from '../../components/Button';
import { useRouteMatch } from 'react-router-dom';
import PickerChoiceSelection from '../../components/PickerChoiceSelection';
import { TabView, Tab } from '../../components/Tabs';
import SubHeader from '../../components/SubHeader';

let history = createBrowserHistory();

export default function AddClientForm(props) {
  const [agent, setAgent] = useState();
  const [client, setClient] = useState('');
  const [status, setStatus] = useState();
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [viewMode, setViewMode] = useState('view');

  let viewPath = useRouteMatch({
    path: '/admin/clients/:id',
    exact: true,
  });

  let editPath = useRouteMatch({
    path: '/admin/clients/:id/edit',
    exact: true,
  });
  let addPath = useRouteMatch({
    path: '/admin/clients/add',
    exact: true,
  });

  useEffect(() => {
    if (addPath) {
      setViewMode('add');
    } else if (editPath) {
      setViewMode('edit');
    } else if (viewPath) {
      setViewMode('view');
    }
  }, [addPath, editPath, viewPath]);

  let isView = viewMode === 'view';

  function addClient() {
    Api.post('/admin/client', {
      agent_id: agent.id,
      client: client,
      status: status,
      address: address,
      city: city,
      country: country,
    }).then((res) => {
      history.goBack();
    });
  }

  function editClient() {
    Api.put('/admin/client/' + props.match.params.id, {
      agent_id: agent.id,
      client: client,
      status: status,
      address: address,
      city: city,
      country: country,
    }).then((res) => {
      history.goBack();
    });
  }

  useEffect(() => {
    const fetchClient = async () => {
      const res = await Api.get('/admin/client/' + props.match.params.id);
      let data = res.data.data;
      setAgent(data.agent);
      setClient(data.client);
      setStatus(data.status);
      setAddress(data.address);
      setCity(data.city);
      setCountry(data.country);
    };
    if (props.match.params.id) {
      fetchClient();
    }
  }, [props.match.params.id]);

  const clearState = () => {};

  const chooseSubHeader = () => {
    if (viewMode === 'view') {
      return <SubHeader title="Πελάτης" />;
    } else if (viewMode === 'add') {
      return <SubHeader title="Προσθέστε Πελάτη" />;
    } else if (viewMode === 'edit') {
      return <SubHeader title="Επεξεργασία Πελάτη" />;
    }
  };

  function renderButtons() {
    if (viewMode === 'view') {
      return null;
    }
    if (viewMode === 'add') {
      return (
        <View style={styles.buttonForm}>
          <Button
            onPress={() => addClient()}
            style={styles.button}
            title={'Save'}
          />
          <Button
            onPress={() => clearState()}
            style={styles.button}
            title={'Cancel'}
          />
        </View>
      );
    } else {
      return (
        <View style={styles.buttonForm}>
          <Button
            onPress={() => editClient()}
            style={styles.button}
            title={'Save'}
          />
          <Button
            onPress={() => clearState()}
            style={styles.button}
            title={'Cancel'}
          />
        </View>
      );
    }
  }

  return (
    <View style={styles.container}>
      {chooseSubHeader()}

      <View style={styles.form} />
      <InputLookUp
        viewMode={isView}
        style={styles.picker}
        displayTitle="Πάροχος"
        displayKey="agent"
        endpoint="admin/agents"
        searchQueryKey="search"
        onSelect={(item) => setAgent(item)}
        value={agent}
      >
        {agent ? agent.agent : ''}
      </InputLookUp>
      <PickerChoiceSelection
        items={[
          { value: 0, label: 'Disabled', key: 0 },
          { value: 1, label: 'Enabled', key: 1 },
        ]}
        selectedValue={status}
        onValueChange={(item) => setStatus(item)}
        displayTitle="Κατάσταση"
        viewMode={isView}
        value={status}
      />
      <InputText
        viewMode={isView}
        displayTitle="Πελατης"
        style={styles.inputText}
        value={client}
        onChangeText={(e) => setClient(e)}
        multiline={true}
        placeholder="Add Client"
      />
      <InputText
        viewMode={isView}
        displayTitle="Διευθυνση"
        style={styles.inputText}
        value={address}
        onChangeText={(e) => setAddress(e)}
        multiline={true}
        placeholder="Add Address"
      />

      <InputText
        viewMode={isView}
        displayTitle="Πόλη"
        style={styles.inputText}
        value={city}
        onChangeText={(e) => setCity(e)}
        multiline={true}
        placeholder="Add City"
      />
      <InputText
        viewMode={isView}
        displayTitle="Χώρα"
        style={styles.inputText}
        value={country}
        onChangeText={(e) => setCountry(e)}
        multiline={true}
        placeholder="Add name"
      />
      {renderButtons()}
      <TabView style={{ marginTop: 10 }}>
        <Tab title="Εταιρίες" />
        <Tab title="Χρήστες" />
      </TabView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '20px',
  },

  firstText: {
    fontSize: 20,
    borderBottomColor: 'blue',
    borderBottomWidth: 0.5,
    borderStyle: 'dotted',
    paddingRight: 180,
  },

  form: {
    flex: 1,
  },

  inputText: {
    width: 280,
    height: 80,
    borderRadius: 15,
    margin: 0,
    borderWidth: 0.5,
    borderColor: 'black',
    padding: 0,
    marginTop: 15,
  },

  buttonForm: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },

  button: {
    width: 120,
    borderRadius: 5,
    alignSelf: 'flex-end',
    marginLeft: 15,
    marginTop: 25,
  },

  picker: {
    width: 200,
    height: 30,
    margin: 15,
    marginLeft: 200,
  },
});
