import React from 'react';
import { View, Text, StyleSheet, TextInput } from 'react-native-web';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import * as sitesActions from '../../redux/actions/sites';

class Sites extends React.Component {
  state = {
    filteredSites: [],
    searchInput: '',
  };

  componentDidMount() {
    this.props.fetchSites();
  }

  filterSites = (text) => {
    this.setState({
      searchInput: text,
      filteredSites: this.props.sites.filter((item) =>
        item.name.includes(text)
      ),
    });
  };

  renderItem = (item) => {
    return (
      <View style={styles.container}>
        <Button
          style={styles.view}
          onPress={() => this.props.history.push(`/sites/${item.id}`)}
        >
          <Text style={styles.text}> {'Name: ' + item.name} </Text>
          <Text style={styles.text}> {'Status: ' + item.status} </Text>
          <Text style={styles.text}> {'Desc: ' + item.description} </Text>
          <Text style={styles.text}> {'Location: ' + item.location} </Text>
        </Button>
      </View>
    );
  };

  render() {
    let filteredSites =
      !this.state.searchInput && this.state.filteredSites.length === 0
        ? this.props.sites
        : this.state.filteredSites;
    return (
      <View>
        <View>
          <TextInput
            placeholder="search"
            style={styles.textInputStyle}
            value={this.state.searchInput}
            onChangeText={this.filterSites}
          />
        </View>
        <View>{filteredSites.map(this.renderItem)}</View>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.sitesReducer });

export default connect(mapStateToProps, sitesActions)(Sites);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'White',
    padding: '10px',
  },

  view: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#bdbdbd',
    margin: '5px',
    padding: '25px',
    justifyContent: 'space-between',
  },
  text: {
    wordSpacing: '30px',
  },

  textInputStyle: { width: 250, height: 30, borderWidth: 1 },
});
