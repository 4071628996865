import React, { Component } from 'react';
import { View, Text, ScrollView } from 'react-native-web';
import Api from '../../api/Api';
import CustomMap from '../../components/Map';
import Notes from '../../components/Notes';
import Calendar from '../../components/Calendar';
import EStyleSheet from 'react-native-extended-stylesheet';
import SubHeader from '../../components/SubHeader';
import CardBlock from '../../components/CardBlock';
import Table from '../../components/Table';

class AdminDashboard extends Component {
  state = {
    works: [],
    companies: [],
    staff: [],
    workPage: 0,
    staffPage: 0,
    companyPage: 0,
    companiesRefreshing: false,
    worksRefreshing: false,
    staffRefreshing: false,
  };

  fetchCompanies = async (page = this.state.companyPage) => {
    this.setState({ companiesRefreshing: true });
    let res = await Api.get('admin/companies', { params: { page: page } });
    const data = res.data.data;
    if (data.length) {
      this.setState({
        companies: [...this.state.companies, ...data],
        companyPage: page + 1,
      });
    }

    this.setState({ companiesRefreshing: false });
  };

  fetchWorks = async (page = this.state.workPage) => {
    this.setState({ worksRefreshing: true });
    let res = await Api.get('admin/works', { params: { page: page } });
    const data = res.data.data;
    if (data.length) {
      this.setState({
        works: [...this.state.works, ...data],
        workPage: page + 1,
      });
    }
    this.setState({ worksRefreshing: false });
  };

  fetchStaff = async (page = this.state.staffPage) => {
    this.setState({ staffRefreshing: true });
    let res = await Api.get('admin/staff', { params: { page: page } });
    const data = res.data.data;
    if (data.length) {
      this.setState({
        staff: [...this.state.staff, ...data],
        staffPage: page + 1,
      });
    }

    this.setState({ staffRefreshing: false });
  };

  componentDidMount() {
    this.fetchWorks(0);
    this.fetchCompanies(0);
    this.fetchStaff(0);
  }

  renderCompany = (item) => {
    return (
      <View style={styles.renderView}>
        <Text>{item.id}</Text>
        <Text>{item.name}</Text>
        <Text>{item.client_id}</Text>
        <Text>{item.address}</Text>
        <Text>{item.country}</Text>
      </View>
    );
  };
  renderStaff = (item) => {
    return (
      <View style={styles.renderView}>
        <Text>{item.firstname}</Text>
        <Text>{item.lastname}</Text>
        <Text>{item.mobile}</Text>
        <Text>{item.tel}</Text>
        <Text>{item.email}</Text>
      </View>
    );
  };

  renderWorks = (item) => {
    return (
      <View>
        <View style={styles.renderWorksView}>
          <Text style={styles.itemsText}>{item.id}</Text>
          <Text style={styles.itemsText}>{item.service_tasks_template_id}</Text>
          <Text style={styles.itemsText}>{item.client_id}</Text>
          <Text style={styles.itemsText}>{item.company_id}</Text>
          <Text style={styles.itemsText}>{item.admin_comment}</Text>
        </View>
      </View>
    );
  };

  onChange = (date) => this.setState({ date });

  render() {
    return (
      <View style={styles.wrapper}>
        <SubHeader title="Main Dashboard" />
        <View style={styles.row}>
          <View style={styles.column}>
            <CardBlock displayTitle="SITES">
              <CustomMap title={'site'} />
            </CardBlock>
          </View>
          <View style={styles.column}>
            <CardBlock displayTitle="NOTES">
              <Notes />
            </CardBlock>
          </View>
        </View>

        <ScrollView style={[styles.scrollViewHeight, { marginTop: 20 }]}>
          <CardBlock
            displayTitle="Works"
            titleStyle={{ marginTop: 25, fontSize: 20 }}
          >
            <View style={styles.row}>
              <View style={[styles.column]}>
                <Table
                  rowStyle={styles.rowStyle}
                  data={this.state.works}
                  dataMap={{
                    id: 'id',
                    client: 'Client',
                    name: 'Name',
                    service: 'Service',
                    date_announced: 'Date Announced',
                    date_resolved: 'Date Resolved',
                  }}
                />
              </View>
            </View>
          </CardBlock>
        </ScrollView>

        <View style={styles.row}>
          <View style={styles.column}>
            <View style={styles.Calendar}>
              <Calendar />
            </View>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.column}>
            <ScrollView style={styles.scrollViewHeight}>
              <CardBlock displayTitle="Company">
                <View style={styles.row}>
                  <View style={[styles.column]}>
                    <Table
                      rowStyle={styles.rowStyle}
                      data={this.state.companies}
                      dataMap={{
                        name: 'Company',
                        contactsearch: 'Contact Name',
                      }}
                    />
                  </View>
                </View>
              </CardBlock>
            </ScrollView>
          </View>
          <View style={styles.column}>
            <ScrollView style={styles.scrollViewHeight}>
              <CardBlock displayTitle="Staff">
                <View style={styles.row}>
                  <View style={[styles.column]}>
                    <Table
                      rowStyle={styles.rowStyle}
                      data={this.state.staff}
                      dataMap={{
                        lastname: 'Last Name',
                        firstname: 'First Name',
                        mobile: 'mobile',
                        email: 'email',
                      }}
                    />
                  </View>
                </View>
              </CardBlock>
            </ScrollView>
          </View>
        </View>
      </View>
    );
  }
}

export default AdminDashboard;

const styles = EStyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: '#F5F5F5',
    justifyContent: 'center',
    margin: 5,
  },
  row: {
    flexDirection: 'row',
    position: 'relative',
  },

  column: {
    flex: 1,
  },

  itemsText: {
    flex: 1,
    fontWeight: 'bold',
    justifyContent: 'space-between',
  },

  renderWorksView: {
    backgroundColor: 'white',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },

  renderView: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    margin: 15,
  },

  rowStyle: {
    backgroundColor: '#a4bfeb',
    marginBottom: 15,
    textTransform: 'uppercase',
  },

  scrollViewHeight: {
    height: 400,
  },
});
