import React, { Component } from "react";
import { View, StyleSheet, Text } from "react-native";

class Statistics extends Component {
  state = {};
  render() {
    return (
      <View style={styles.scrollContainer}>
        <View style={styles.container}>
          <View style={styles.row}>
            <View style={styles.column}>
              <View style={[styles.box, styles.totalFaults]}>
                {" "}
                <Text>1 Total Faults</Text>
              </View>
              <View
                style={[styles.box, styles.closedFaults, styles.responseTime]}>
                <Text>15h Response time</Text>
              </View>
            </View>
            <View style={styles.column}>
              <View style={[styles.box, styles.closedFaults]}>
                <Text>0 Pending Faults</Text>
              </View>
              <View style={[styles.box, styles.closedFaults]}>
                <Text>0 Closed Faults</Text>
              </View>
              <View style={[styles.box, styles.performanceRatio]}>
                <Text>89% Performance Ratio</Text>
              </View>
            </View>
            <View style={styles.column}>
              <View style={[styles.box, styles.closedFaults]}>
                <Text>0 High priority Faults</Text>
              </View>
              <View style={[styles.box, styles.closedFaults]}>
                <Text> 0 Canceled Faults </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

export default Statistics

const styles = StyleSheet.create({
  scrollContainer: {
    flex: 1,
    flexDirection: "column",
    flexWrap: "wrap",
    padding: 2,
    backgroundColor: "White",
    height: "100vh",
  },
  container: {
    flex: 1,
    margin: 15,
    alignItems: "center",
    flexWrap: "wrap",
  },
  row: {
    flex: 1,
    flexDirection: "row",
    fleWrap: "wrap",
    width: "100%",
  },
  column: {
    flex: 1,
    flexDirection: "column",
  },

  box: {
    margin: 5,
    backgroundColor: "limegreen",
    justifyContent: "center",
    alignSelf: "center",
  },

  totalFaults: {
    width: "80%",
    height: "30%",
    padding: "20px",
    borderRadius: "5%",
    backgroundColor: "#1F85DE",
  },

  closedFaults: {
    width: "80%",
    height: "15%",
    padding: "20px",
    borderRadius: "5%",
    alignSelf: "center",
  },

  performanceRatio: {
    width: "150%",
    height: "10%",
    margin: 50,
    alignSelf: "flex-start",
  },

  responseTime: {
    marginTop: 50,
    padding: "30px",
    backgroundColor: "#1F85DE",
  },
});
