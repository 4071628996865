import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  ScrollView,
  ImageBackground,
  Image,
} from 'react-native-web';
import Card from '../../components/Card';
import moment from 'moment';
import { connect } from 'react-redux';
import * as sitesActions from '../../redux/actions/sites';
import * as notificationActions from '../../redux/actions/notifications';

const DATA = { first_name: 'Mike' };

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      date: '',
    };
  }

  componentDidMount() {
    this.props.fetchSites();
    this.props.fetchNotifications(0);
    var that = this;
    var date = new Date().getDate();
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    var hours = new Date().getHours();
    var min = new Date().getMinutes();
    var sec = new Date().getSeconds();
    that.setState({
      date:
        date + '/' + month + '/' + year + ' ' + hours + ':' + min + ':' + sec,
    });
  }

  renderCardComponent = (item) => {
    return <Card name={item.name} power={item.power} id={item.id} />;
  };

  render() {
    return (
      <ScrollView style={styles.scrollContainer}>
        <View style={styles.container}>
          <View style={styles.row}>
            <View style={styles.column}>
              <View style={[styles.weatherBox, { marginBottom: 5 }]}>
                <Image
                  source={require('../../assets/images/sunny.svg')}
                  style={styles.image}
                />
                <Text style={styles.textColor}>35 °C</Text>
                <Text style={styles.textColor}>Athens , Greece</Text>
              </View>
              <View style={styles.dateBox}>{this.state.date}</View>
            </View>

            <View style={[styles.column, styles.imageContainer]}>
              <View>
                <ImageBackground
                  style={styles.imageBackground}
                  source={require('../../assets/images/backgroundImage.jpg')}
                >
                  <Text>{`Hello ${DATA.first_name}`}</Text>
                </ImageBackground>
                <View style={{ flexDirection: 'row' }}>
                  {this.props.sites.sites.map(this.renderCardComponent)}
                </View>
              </View>
            </View>

            <View style={styles.column}>
              <View style={[styles.notificationBox]}>
                <Text style={styles.textColor}>Notifications</Text>
                <View>
                  <View>
                    {this.props.notifications.notifications
                      .slice(0, 2)
                      .map((item) => {
                        return (
                          <View style={styles.notification}>
                            <Text style={styles.whiteText}>
                              Title {item.title}
                            </Text>
                            <Text style={styles.whiteText}>
                              Date {moment(item.created).format('LLL')}
                            </Text>
                          </View>
                        );
                      })}
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        {/* End */}

        {/* Production  */}
        <View style={styles.productionContainer}>
          <View style={[styles.box, styles.dailyProductionBox]}>
            <Text style={styles.textColor}>Daily Production</Text>
            <Text style={styles.textColor}>395,060</Text>
          </View>

          <View style={[styles.box, styles.lastWeekProductionBox]}>
            <Text style={styles.textColor}>Last Week Production</Text>
            <Text style={styles.textColor}>2,536,930</Text>
          </View>

          <View style={[styles.box, styles.lastYearProductionBox]}>
            <Text style={styles.textColor}>Last Year Production</Text>
            <Text style={styles.textColor}>44,832,644</Text>
          </View>

          <View style={[styles.box, styles.totalProductionBox]}>
            <Text style={styles.textColor}>TotalProduction</Text>
            <Text style={styles.textColor}>215,306,716</Text>
          </View>
        </View>

        {/* End */}

        {/* Maintance Container */}
        <View style={styles.maintenanceContainer}>
          <View style={styles.maintenanceBox}>
            <Text style={[styles.fontWeight, { fontSize: '26' }]}>
              Previous Maintenances
            </Text>
            <View style={styles.maintenanceStyle}>
              <Text style={styles.fontWeight}>Work id</Text>
              <Text style={styles.fontWeight}>PV Plant Name</Text>
              <Text style={[styles.fontWeight, { marginRight: '50px' }]}>
                Date
              </Text>
            </View>

            <View style={styles.information}>
              <Text>56</Text>
              <Text>PV1 </Text>
              <Text style={{ marginRight: '15px' }}>2020-11-01</Text>
            </View>
          </View>
          <View style={styles.maintenanceBox}>
            <Text style={styles.fontWeight}>Scheduled Maintenances</Text>
          </View>
        </View>
        {/* End */}
      </ScrollView>
    );
  }
}

const mapStateToProps = (state) => ({
  sites: state.sitesReducer,
  notifications: state.notificationsReducer,
});

const mapActions = {
  fetchNotifications: notificationActions.fetchNotifications,
  fetchSites: sitesActions.fetchSites,
};

export default connect(mapStateToProps, mapActions)(Dashboard);

const styles = StyleSheet.create({
  scrollContainer: {
    flex: 1,
    padding: 2,
    backgroundColor: '#ECF0F1',
    marginTop: 2,
  },
  container: {
    margin: 35,
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  column: {
    flex: 1,
  },
  productionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
  },
  maintenanceContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 2,
    justifyContent: 'space-around',
    marginTop: 5,
  },

  box: {
    margin: 1,
    width: '23%',
    height: '80px',
    justifyContent: 'center',
    alignItems: 'center',
  },

  dateBox: {
    backgroundColor: 'green',
    height: '150px',
    width: '80%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  maintenanceBox: {
    margin: '25px',
    width: '40%',
    height: '100px',
    backgroundColor: 'lightgray',
    justifyContent: 'flex-start',
  },

  notificationBox: {
    backgroundColor: 'green',
    justifyContent: 'center',
    alignSelf: 'center',
  },

  weatherBox: {
    backgroundColor: 'blue',
    height: '150px',
    width: '80%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  informationBox: {
    backgroundColor: 'white',
    height: '40%',
    width: '100%',
    alignSelf: 'flex-end',
  },

  dailyProductionBox: {
    backgroundColor: '#1f45de',
  },

  lastWeekProductionBox: {
    backgroundColor: '#1f45de',
  },

  lastYearProductionBox: {
    backgroundColor: 'green',
  },

  totalProductionBox: {
    backgroundColor: 'green',
  },

  image: {
    width: '45%',
    height: '50%',
    marginBottom: 5,
  },
  imageBackground: {
    width: '100%',
    height: 200,
  },

  imageContainer: {
    flex: 1,
    backgroundColor: 'white',
    marginRight: 41,
    flexGrow: 2.8,
  },
  textColor: {
    color: 'white',
  },

  textWeight: {
    fontWeight: 'bold',
  },

  maintenanceStyle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
  },

  information: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  notification: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    padding: 10,
  },

  whiteText: {
    color: 'white',
  },
});
