import {
  FETCH_NOTES_FAILURE,
  FETCH_NOTES_REQUEST,
  FETCH_NOTES_SUCCESS,
  ADD_NOTE,
  DELETE_NOTE,
  EDIT_NOTE,
} from '../types/notes';

const initialState = {
  loading: false,
  notes: [],
  error: '',
  page: 0,
};

export const notesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NOTES_SUCCESS:
      return {
        loading: false,
        notes:
          action.payload.page !== 0
            ? state.notes.concat(action.payload.notes)
            : action.payload.notes,
        error: '',
        page: action.payload.page,
      };
    case FETCH_NOTES_FAILURE:
      return {
        loading: false,
        notes: [],
        error: action.payload,
      };

    case ADD_NOTE: {
      return {
        ...state,
        notes: [action.payload, ...state.notes],
        error: '',
      };
    }

    case DELETE_NOTE: {
      const filteredArray = state.notes.filter(
        (item) => item.note_id !== action.payload.note_id
      );

      return {
        ...state,
        notes: filteredArray,
      };
    }
    case EDIT_NOTE: {
      return {
        ...state,
        notes: [action.payload, ...state.notes],
      };
    }
    default:
      return state;
  }
};
