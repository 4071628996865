import React from 'react';
import LoginScreen from './pages/Login';
import Sites from './pages/client/Sites';
import Site from './pages/client/Site';
import Dashboard from './pages/client/Dashboard';
import Works from './pages/client/Works';
import Layout from './components/Layout';
import Profile from './pages/client/Profile';
import Statistics from './pages/client/Statistics';
import Analytics from './pages/client/Analytics';
import Work from './pages/client/Work';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import AdminSites from './pages/admin/Sites';
import AdminClients from './pages/admin/Clients';
import Notifications from './pages/notifications';
import Companies from './pages/admin/Companies';
import AdminDashboard from './pages/admin/Dashboard';
import Users from './pages/admin/Users';
import UserForm from './pages/admin/UserForm';
import adminAnalytics from './pages/admin/Analytics';
import AdminTasks from './pages/admin/Tasks';
import AdminWorks from './pages/admin/Works';
import AdminLayout from './components/AdminLayout';
import ClientForm from './pages/admin/ClientForm';
import SitesForm from './pages/admin/SitesForm';
import CompanyForm from './pages/admin/CompanyForm';
import WorksForm from './pages/admin/WorksForm';
import EStyleSheet from 'react-native-extended-stylesheet';
import WorkTasksForm from './components/WorkTasksForm';
import RouteWithLayout from './components/RouteWithLayout';

function App() {
  EStyleSheet.build();
  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path="/login" component={LoginScreen} />

            <RouteWithLayout
              layout={AdminLayout}
              exact
              path="/admin/sites"
              component={AdminSites}
            />
            <RouteWithLayout
              layout={AdminLayout}
              exact
              path="/admin/clients"
              component={AdminClients}
            />
            <RouteWithLayout
              layout={AdminLayout}
              exact
              path="/admin/companies"
              component={Companies}
            />
            <RouteWithLayout
              layout={AdminLayout}
              exact
              path="/admin/dashboard"
              component={AdminDashboard}
            />
            <RouteWithLayout
              layout={AdminLayout}
              exact
              path="/admin/tasks"
              component={AdminTasks}
            />
            <RouteWithLayout
              layout={AdminLayout}
              exact
              path="/admin/works"
              component={AdminWorks}
            />
            <RouteWithLayout
              layout={AdminLayout}
              exact
              path="/admin/works/:id/tasks"
              component={WorkTasksForm}
            />
            <RouteWithLayout
              layout={AdminLayout}
              exact
              path="/admin/users"
              component={Users}
            />
            <RouteWithLayout
              layout={AdminLayout}
              exact
              path="/admin/analytics"
              component={adminAnalytics}
            />

            <RouteWithLayout
              layout={AdminLayout}
              exact
              path={[
                '/admin/works/add ',
                '/admin/works/:id',
                '/admin/works/:id/edit',
              ]}
              component={WorksForm}
            />

            <RouteWithLayout
              layout={AdminLayout}
              exact
              path={[
                '/admin/clients/add',
                '/admin/clients/:id',
                '/admin/clients/:id/edit',
              ]}
              component={ClientForm}
            />
            <RouteWithLayout
              layout={AdminLayout}
              exact
              path={[
                '/admin/sites/add',
                '/admin/sites/:id',
                '/admin/sites/:id/edit',
              ]}
              component={SitesForm}
            />
            <RouteWithLayout
              layout={AdminLayout}
              exact
              path={[
                '/admin/users/add',
                '/admin/users/:id',
                '/admin/users/:id/edit',
              ]}
              component={UserForm}
            />

            <RouteWithLayout
              layout={AdminLayout}
              exact
              path={[
                '/admin/companies/add',
                '/admin/companies/:id',
                '/admin/companies/:id/edit',
              ]}
              component={CompanyForm}
            />

            <RouteWithLayout
              layout={Layout}
              exact
              path="/dashboard"
              component={Dashboard}
            />
            <RouteWithLayout
              layout={Layout}
              exact
              path="/analytics"
              component={Analytics}
            />
            <RouteWithLayout
              layout={Layout}
              exact
              path="/statistics"
              component={Statistics}
            />
            <RouteWithLayout
              layout={Layout}
              exact
              path="/works"
              component={Works}
            />
            <RouteWithLayout
              layout={Layout}
              exact
              path="/profile"
              component={Profile}
            />
            <RouteWithLayout
              layout={Layout}
              exact
              path="/sites"
              component={Sites}
            />
            <RouteWithLayout
              layout={Layout}
              exact
              path="/works/:id"
              component={Work}
            />
            <RouteWithLayout
              layout={Layout}
              exact
              path="/sites/:id"
              component={Site}
            />
            <RouteWithLayout
              layout={Layout}
              exact
              path="/notifications"
              component={Notifications}
            />
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
