import React, { Component } from 'react';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { View, Text, StyleSheet } from 'react-native';

class AssignDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      focused: false,
    };
  }

  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>{this.props.displayTitle}</Text>
        <SingleDatePicker
          date={this.props.date}
          onDateChange={this.props.onDateChange}
          focused={this.state.focused} // PropTypes.bool
          onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
          displayFormat="YYYY-MM-DD"
          id={this.props.id}
        />
      </View>
    );
  }
}

export default AssignDate;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    zIndex: 15,
  },

  text: {
    width: '15%',
    marginTop: 20,
  },
});
