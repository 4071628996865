import React, { Component } from 'react';
import {
  Text,
  TouchableHighlight,
  View,
  StyleSheet,
  FlatList,
} from 'react-native';
import Modal from 'modal-enhanced-react-native-web';
import Api from '../api/Api';
import PropTypes from 'prop-types';
import { TextInput, TouchableOpacity } from 'react-native-web';

export default class ModalView extends Component {
  static propTypes = {
    endpoint: PropTypes.string.isRequired,
    searchQueryKey: PropTypes.string,
    onSelect: PropTypes.func,
    queryParams: PropTypes.object,
  };

  static defaultProps = {
    searchQueryKey: 'search',
    queryParams: {},
  };

  state = {
    modalVisible: false,
    data: [],
    searchInput: '',
  };

  setModalVisible = (visible) => {
    this.setState({ modalVisible: visible });
  };

  componentDidMount() {
    this.search();
  }

  searchTimeout;
  inputChange = (text) => {
    this.setState({ searchInput: text });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => this.search(text), 1000);
  };

  search = () => {
    Api.get(this.props.endpoint, {
      params: {
        ...this.props.queryParams,
        [this.props.searchQueryKey]: this.state.searchInput,
      },
    })
      .then((res) => {
        const data = res.data.data;
        this.setState({ data: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onSelect = (item) => {
    this.setModalVisible(false);
    this.props.onSelect(item);
  };

  renderItem = ({ item }) => {
    return (
      <TouchableOpacity onPress={() => this.onSelect(item)}>
        <Text>
          {item[this.props.displayKey]}
          {item[this.props.displaySecondKey]}
        </Text>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <View style={[styles.centeredView, { marginTop: 22 }]}>
        <Modal
          onBackdropPress={() => this.setState({ modalVisible: false })}
          isVisible={this.state.modalVisible}
        >
          <View style={styles.modalView}>
            <TextInput onChangeText={this.inputChange} placeholder="search" />
            <FlatList data={this.state.data} renderItem={this.renderItem} />
          </View>
        </Modal>

        <TouchableHighlight
          style={styles.openButton}
          onPress={() => {
            this.setModalVisible(true);
          }}
        >
          <Text>Search</Text>
        </TouchableHighlight>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    alignItems: 'flex-start',
    bottom: 10,
  },
  modalView: {
    width: '50%',
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 20,
    alignSelf: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  openButton: {
    padding: 5,
    elevation: 2,
    backgroundColor: '#bbb',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
});
