import React, { Component } from 'react';
import { View, TextInput, StyleSheet, Text } from 'react-native-web';
import Api from '../../api/Api';
import Table from '../../components/Table';
import SubHeader from '../../components/SubHeader';
import Button from '../../components/Button';

class Companies extends Component {
  state = {
    data: [],
    searchInput: '',
    filters: {},
    filteredData: [],
    formDisplay: true,
  };

  componentDidMount() {
    this.fetchCompanies();
  }

  fetchCompanies = () => {
    Api.get('/admin/companies', {
      params: {
        client_id: this.state.filters.client_id,
        search: this.state.searchInput,
      },
    })
      .then((res) => {
        let data = res.data.data;
        console.log(data);
        this.setState({ data: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  searchTimeout;
  filteredCompanies = (text) => {
    this.setState({
      searchInput: text,
      filteredData: this.state.data.filter((item) => item.name.includes(text)),
    });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => this.fetchCompanies(), 1000);
  };

  Toggle() {
    this.setState((currentState) => ({
      formDisplay: !currentState.formDisplay,
    }));
  }

  deleteCompany = (id) => {
    Api.delete('/admin/companies/ ' + id).then((res) => {
      console.log('deleted item with id' + id);
    });
  };

  render() {
    let filteredCompanies =
      !this.state.searchInput && this.state.filteredData.length === 0
        ? this.state.data
        : this.state.filteredData;

    filteredCompanies = filteredCompanies.map((item) => {
      item.button = (
        <Button onPress={() => this.deleteCompany(item.id)} title="Delete" />
      );
      return item;
    });

    this.state.data.map((item) => {
      return (item.viewButton = (
        <Button
          title="view"
          onPress={() => this.props.history.push(`/admin/companies/${item.id}`)}
        />
      ));
    });

    this.state.data.map((item) => {
      return (item.editButton = (
        <Button
          title="edit"
          onPress={() => this.props.history.push(`companies/${item.id}/edit`)}
        />
      ));
    });

    return (
      <View style={styles.container}>
        <SubHeader title="Εταιρίες" />
        <TextInput
          placeholder="search"
          onChangeText={this.filteredCompanies}
          value={this.state.searchInput}
          style={styles.input}
        />

        <Button
          onPress={() => this.props.history.push('/admin/companies/add')}
          style={styles.button}
        >
          <Text>Add New Company</Text>
        </Button>
        <Table
          data={filteredCompanies}
          dataMap={{
            name: 'Όνομα',
            city: 'Πόλη',
            country: 'Χώρα',
            address: 'Διεύθυνση',
            post_code: 'Ταχ.Κωδ.',
            tax_authority: 'Εφορία',
            vat: 'Α.Φ.Μ',
            button: '',
            viewButton: '',
            editButton: '',
          }}
          rowStyle={styles.rowStyle}
        />
      </View>
    );
  }
}

export default Companies;

const styles = StyleSheet.create({
  container: {
    borderSizing: 'borderBox',
  },

  text: {
    fontSize: 26,
    color: 'blue',
    borderBottomColor: 'blue',
    borderBottomWidth: 0.5,
    paddingRight: 180,
    borderStyle: 'dotted',
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  input: {
    width: '20%',
    borderWidth: 1,
    padding: 10,
    borderRadius: 15,
    marginTop: 25,
  },

  rowStyle: {
    backgroundColor: '#a4bfeb',
    marginBottom: 15,
    textTransform: 'uppercase',
  },
  button: {
    width: 180,
    marginTop: 15,
  },
});
