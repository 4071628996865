import React, { Component } from 'react';
import ModalView from './ModalView';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native-web';

export default class InputLookUp extends Component {
  modalRef = React.createRef();
  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.title}> {this.props.displayTitle}</Text>
        {!this.props.viewMode ? (
          <View style={styles.modal}>
            <TouchableOpacity
              style={styles.border}
              onPress={() => this.modalRef.current.setModalVisible(true)}
            >
              <Text> {this.props.value?.[this.props.displayKey]} </Text>
            </TouchableOpacity>
            <ModalView
              ref={this.modalRef}
              displayKey={this.props.displayKey}
              endpoint={this.props.endpoint}
              searchQueryKey={this.props.search}
              onSelect={this.props.onSelect}
            />
          </View>
        ) : (
          <Text>{this.props.value}</Text>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  border: {
    padding: 15,
    borderWidth: 1,
    marginLeft: 5,
    width: '30%',
  },

  title: {
    fontSize: 16,
  },

  modal: {
    flexDirection: 'row',
  },
});
