import React, { Component } from 'react';
import { View, StyleSheet, Text } from 'react-native-web';

export default class AdminTasks extends Component {
  state = {};

  componentDidMount() {
    console.log('hello');
  }
  render() {
    console.log('hello');
    return (
      <View style={styles.container}>
        <View style={styles.header}>
          <Text style={styles.text}>Tasks</Text>
          <Text>Tasks</Text>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    alignSelf: 'flexStart',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  text: {
    fontSize: 24,
    color: 'blue',
  },
});
