import {
  FETCH_NOTES_REQUEST,
  FETCH_NOTES_SUCCESS,
  FETCH_NOTES_FAILURE,
  ADD_NOTE,
  DELETE_NOTE,
  EDIT_NOTE,
} from '../types/notes';
import Api from '../../api/Api';

export const fetchNotesRequest = () => {
  return {
    type: FETCH_NOTES_REQUEST,
  };
};
export const fetchNotesSuccess = ({ notes, page }) => {
  return {
    type: FETCH_NOTES_SUCCESS,
    payload: { notes, page },
  };
};

export const fetchNotesFailure = (error) => {
  return {
    type: FETCH_NOTES_FAILURE,
    payload: error,
  };
};

export const addNote = ({ note, color_hex, note_id, created }) => {
  return {
    type: ADD_NOTE,
    payload: {
      note: note,
      color_hex: color_hex,
      note_id: note_id,
      created: created,
    },
  };
};

export const deleteNote = (id) => {
  return {
    type: DELETE_NOTE,
    payload: {
      note_id: id,
    },
  };
};

export const editNote = ({ id, note, color_hex }) => {
  return {
    type: EDIT_NOTE,
    payload: {
      note_id: id,
      note: note,
      color_hex: color_hex,
    },
  };
};

export const fetchNotes = (page = null) => {
  return function (dispatch, getState) {
    let state = getState();
    let newPage = page === null ? state.notesReducer.page + 1 : page;
    console.log(state);
    dispatch(fetchNotesRequest());
    Api.get('notes', { params: { page: newPage } })
      .then((res) => {
        const data = res.data.data;
        dispatch(fetchNotesSuccess({ notes: data, page: newPage }));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const addNotes = ({ note, color_hex, note_id, created }) => {
  return async (dispatch) => {
    Api.post('notes', {
      note: note,
      color_hex: color_hex,
      note_id: note_id,
      created: created,
    })
      .then((res) => {
        let data = res.data.data;
        console.log(data);
        dispatch(
          addNote({
            note: data.note,
            color_hex: data.color_hex,
            note_id: data.note_id,
            created: data.created,
          })
        );
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const deleteNotes = (id) => {
  return async (dispatch) => {
    await Api.delete('notes/' + id);
    dispatch(deleteNote(id));
  };
};

export const editNotes = ({ id, note, color_hex }) => {
  return async (dispatch) => {
    await Api.put('notes/' + id, {
      id: id,
      note: note,
      color_hex: color_hex,
    });
    dispatch(editNote({ id, note, color_hex }));
  };
};
