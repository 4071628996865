import React from 'react';
import { View, Text, TextInput, StyleSheet } from 'react-native';
import axios from 'axios';
import Button from '../components/Button';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import * as accountActions from '../redux/actions/account';

class LoginScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
  }

  login = () => {
    axios
      .post('http://api.pvsoft.distemicha.online/account/login', {
        username: this.state.email,
        password: this.state.password,
      })
      .then(async (res) => {
        const data = res.data;
        // Save token if success save ok else error

        if (data.status === 'ok') {
          cookie.save('token', data.data.token);
          await this.props.fetchAccount();
          console.log(this.props.account);
          if (this.props.account.role === 'client') {
            window.location.replace('/dashboard');
          } else if (this.props.account.role === 'admin') {
            window.location.replace('/admin/dashboard');
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.layout}>
          <TextInput
            style={styles.input}
            placeholder="email"
            onChangeText={(text) => {
              this.setState({ email: text });
            }}
          />
          <TextInput
            style={styles.input}
            placeholder="password"
            secureTextEntry={true}
            onChangeText={(text) => {
              this.setState({ password: text });
            }}
          />
          <Button onPress={this.login}>
            <Text>Login</Text>
          </Button>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.accountReducer });
export default connect(mapStateToProps, accountActions)(LoginScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100vh',
    width: '100%',
    alignItems: 'center',
    backgroundColor: '#87CEEB',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  input: {
    placeholderTextColor: 'black',
    backgroundColor: 'white',
    borderRadius: '15px',
    margin: '10px',
    padding: '15px',
  },
  layout: {
    padding: '40px',
    backgroundColor: 'lightgray',
  },
});
