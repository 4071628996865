import React, { Component } from 'react';
import { View, StyleSheet, Text } from 'react-native-web';
import Table from '../../components/Table';
import Api from '../../api/Api';
import Button from '../../components/Button';
import Filters from '../../components/Filters';
import SubHeader from '../../components/SubHeader';

class AdminWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      works: [],
      filteredWorks: [],
      page: 0,
      panelDisplay: false,
      searchInput: '',
      companies: [],
    };
  }

  Toggle() {
    this.setState((currentState) => ({
      formDisplay: !currentState.formDisplay,
    }));
  }

  fetchWorks = async ({ page = this.state.page, companyId, companyName }) => {
    let res = await Api.get('admin/works', {
      params: { page: page, company_id: companyId, company_name: companyName },
    });
    const data = res.data.data;
    this.setState({
      works: data,
      page: page + 1,
    });
  };

  deleteWork = async (id) => {
    await Api.delete('admin/works/' + id);
    console.log('deleted item with id ' + id);
  };

  fetchCompanies = async () => {
    let res = await Api.get('admin/companies');
    const data = res.data.data;
    console.log(data);
    this.setState({
      companies: data,
    });
  };

  componentDidMount() {
    this.fetchWorks(0);
    this.fetchCompanies();
  }

  searchTimeout;
  filterWorks = (text) => {
    this.setState({
      searchInput: text,
      filteredWorks: this.state.works.filter((item) =>
        item.name.includes(text)
      ),
    });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => this.fetchWorks(0), 1000);
  };

  render() {
    let filteredWorks =
      !this.state.searchInput && this.state.filteredWorks.length === 0
        ? this.state.works
        : this.state.filteredWorks;

    filteredWorks = filteredWorks.map((item) => {
      item.button = (
        <Button onPress={() => this.deleteWork(item.id)} title="Delete" />
      );
      return item;
    });

    filteredWorks.map((item) => {
      return (item.editButton = (
        <Button
          onPress={() =>
            this.props.history.push(`/admin/works/${item.id}/edit`)
          }
          title="Edit"
        />
      ));
    });
    filteredWorks.map((item) => {
      return (item.viewButton = (
        <Button
          onPress={() => this.props.history.push(`/admin/works/${item.id}`)}
          title="View"
        />
      ));
    });

    return (
      <View style={styles.container}>
        <SubHeader title="Εργασίες/Έργα" />
        <Button style={{ width: 200 }} onPress={() => this.Toggle()}>
          <Text>Close Panel</Text>
        </Button>
        {!this.state.formDisplay && (
          <Filters
            displayTitle="Εταιρία"
            onChangeFilters={(newFilters) => {
              console.log(newFilters);
              this.fetchWorks({
                page: 0,
                companyId: newFilters.company_id,
                companyName: newFilters.name,
              });
            }}
            fields={[
              {
                type: 'InputLookUp',
                key: 'client_id',
                lookupProps: {
                  displayKey: 'agent',
                  displayTitle: 'Πελατης',
                  endpoint: 'admin/agents',
                  searchQueryKey: 'search',
                },
              },
              {
                type: 'Picker',
                key: 'company_id',

                items: this.state.companies.map((item) => ({
                  key: item.id,
                  value: item.id,
                  label: item.name,
                })),
              },
            ]}
          />
        )}

        <Button
          onPress={() => this.props.history.push('/admin/companies/add')}
          style={styles.button}
        >
          <Text>Add New Work</Text>
        </Button>
        <Table
          data={filteredWorks}
          dataMap={{
            client: 'Πελάτης',
            name: 'Εταιρία',
            site_name: 'Εγκατάσταση',
            button: '',
            editButton: '',
            viewButton: '',
          }}
          rowStyle={styles.rowStyle}
        />
      </View>
    );
  }
}

export default AdminWorks;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    alignSelf: 'flexStart',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  text: {
    fontSize: 24,
    color: 'blue',
  },

  inputDisplay: {
    padding: 5,
    borderWidth: 1,
    width: '20%',
    borderRadius: '5%',
    marginTop: 15,
  },

  button: {
    padding: 15,
    width: 180,
  },
  rowStyle: {
    backgroundColor: '#a4bfeb',
    marginBottom: 15,
    textTransform: 'uppercase',
  },
});
