import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native-web';
import Api from '../../api/Api';
import Button from '../../components/Button';
import { createBrowserHistory } from 'history';
import InputText from '../../components/InputText';
import InputLookUp from '../../components/InputLookUp';
import { useRouteMatch } from 'react-router-dom';
import PickerChoiceSelection from '../../components/PickerChoiceSelection';
import SubHeader from '../../components/SubHeader';

let history = createBrowserHistory();

export default function AddUserForm(props) {
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [agent, setAgent] = useState();
  const [status, setStatus] = useState();
  const [client, setClient] = useState();
  const [notes, setNotes] = useState();
  const [viewMode, setViewMode] = useState('view');

  let viewPath = useRouteMatch({
    path: '/admin/users/:id',
    exact: true,
  });

  let editPath = useRouteMatch({
    path: '/admin/users/:id/edit',
    exact: true,
  });
  let addPath = useRouteMatch({
    path: '/admin/users/add',
    exact: true,
  });

  useEffect(() => {
    if (addPath) {
      setViewMode('add');
    } else if (editPath) {
      setViewMode('edit');
    } else if (viewPath) {
      setViewMode('view');
    }
  }, [addPath, editPath, viewPath]);

  let isView = viewMode === 'view';

  async function addUser() {
    Api.post('/admin/users', {
      password: password,
      first_name: name,
      email: email,
      agent_id: agent.id,
      status: status,
      client_id: client.id,
      notes: notes,
    }).then((res) => {
      let data = res.data.data;
      console.log(data);
      history.goBack();
    });
  }

  async function editUser() {
    Api.put('/admin/users/' + props.match.params.id, {
      password: password,
      first_name: name,
      email: email,
      agent_id: agent.id,
      status: status,
      client_id: client.id,
      notes: notes,
    });
  }

  function renderButtons() {
    if (viewMode === 'view') {
      return null;
    }
    if (viewMode === 'add') {
      return (
        <View style={styles.buttonForm}>
          <Button
            onPress={() => addUser()}
            style={styles.button}
            title={'Save'}
          />
          <Button
            onPress={() => clearState()}
            style={styles.button}
            title={'Cancel'}
          />
        </View>
      );
    } else {
      return (
        <View style={styles.buttonForm}>
          <Button
            onPress={() => editUser()}
            style={styles.button}
            title={'Save'}
          />
          <Button
            onPress={() => clearState()}
            style={styles.button}
            title={'Cancel'}
          />
        </View>
      );
    }
  }

  const chooseSubHeader = () => {
    if (viewMode === 'view') {
      return <SubHeader title="Χρηστης" />;
    } else if (viewMode === 'add') {
      return <SubHeader title="Προσθέστε Χρηστη" />;
    } else if (viewMode === 'edit') {
      return <SubHeader title="Επεξεργασία Χρηστη" />;
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      let res = await Api.get('/admin/users/' + props.match.params.id);
      let data = res.data.data;
      console.log(data);
      setAgent(data.agent);
      setEmail(data.email);
      setClient(data.client);
      setNotes(data.notes);
      setStatus(data.status);
    };
    if (props.match.params.id) {
      fetchUser();
    }
  }, [props.match.params.id, viewMode]);

  function clearState() {
    setPassword('');
    setName('');
    setEmail('');
    setAgent();
    setStatus();
    setClient();
    setNotes();
  }

  return (
    <View style={[styles.container]}>
      {chooseSubHeader()}
      <InputLookUp
        viewMode={isView}
        value={agent}
        style={styles.picker}
        displayTitle="Πάροχος"
        displayKey="agent"
        endpoint="admin/agents"
        searchQueryKey="search"
        onSelect={(item) => setAgent(item)}
      >
        {agent ? agent.agent : ''}
      </InputLookUp>
      <View style={styles.inputView}>
        <Text style={styles.textView}>Name</Text>
        <InputText
          viewMode={isView}
          value={name}
          onChangeText={(e) => setName(e)}
          multiline={true}
          placeholder="Add name"
        />
      </View>
      {!isView ? (
        <View style={styles.inputView}>
          <Text style={styles.textView}>Password</Text>
          <InputText
            viewMode={isView}
            value={password}
            onChangeText={(e) => setPassword(e)}
            multiline={true}
            placeholder="Add password"
          />
        </View>
      ) : (
        ''
      )}
      <View style={styles.inputView}>
        <Text style={styles.textView}>Email</Text>
        <InputText
          viewMode={isView}
          value={email}
          onChangeText={(e) => setEmail(e)}
          multiline={true}
          placeholder="Add email"
        />
      </View>

      <InputLookUp
        viewMode={isView}
        displayTitle="Πελάτης"
        displayKey="client"
        endpoint="admin/client"
        searchQueryKey="search"
        onSelect={(item) => setClient(item)}
      >
        {client ? client.client : ''}
      </InputLookUp>

      <PickerChoiceSelection
        items={[
          { value: 0, label: 'Disabled', key: 0 },
          { value: 1, label: 'Enabled', key: 1 },
        ]}
        selectedValue={status}
        onValueChange={(item) => setStatus(item)}
        displayTitle="Κατάσταση"
        viewMode={isView}
        value={status}
      />

      <InputText
        viewMode={isView}
        displayTitle="Περιγραφή"
        value={notes}
        placeholder="Περιγραφή"
        onChangeText={(e) => setNotes(e)}
      />

      {renderButtons()}
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    marginTop: 20,
    width: 180,
  },

  textView: {
    fontSize: 20,
    maginLeft: 20,
  },

  inputView: {
    margin: 15,
    width: '40%',
  },

  picker: {
    width: 200,
    height: 30,
    margin: 15,
  },
});
