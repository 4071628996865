import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native-web';
import { Table, Row, Rows } from 'react-native-table-component';

class DataTable extends Component {
  tableData = () =>
    this.props.data.map((item) => {
      let newRow = [];
      for (let key in this.props.dataMap) {
        newRow.push(item[key]);
      }

      return newRow;
    });

  tableHead = Object.values(this.props.dataMap);

  render() {
    return (
      <View style={styles.container}>
        <Table borderStyle={[styles.border, this.props.borderStyle]}>
          <Row
            data={this.tableHead}
            style={[styles.head, this.props.rowStyle]}
            textStyle={styles.text}
          />
          <Rows data={this.tableData()} textStyle={styles.text} />
        </Table>
      </View>
    );
  }
}

export default DataTable;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    paddingTop: 30,
    backgroundColor: '#f6f6f6',
  },
  head: { height: 50, backgroundColor: 'blue' },
  text: { margin: 6, fontFamily: 'Roboto', fontSize: 16, fontWeight: 'bold' },
  border: { borderWidth: 2, borderColor: 'red' },
});
