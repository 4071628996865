import React from 'react';
import Api from '../../api/Api';
import { View, Text, StyleSheet } from 'react-native';

export default class Work extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      work: {},
    };
  }

  getOneWork() {
    Api.get('client/works/' + this.props.match.params.id)
      .then((res) => {
        const data = res.data.data;
        console.log(data);
        this.setState({ work: data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.getOneWork();
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.view}>
          <View style={styles.view}>
            <View />
            <Text style={styles.textView}>Work info:</Text>
            <Text>Power:{this.state.work.id}</Text>
            <Text>Installation Date:{this.state.work.service_id}</Text>
            <Text>Initial Performance:{this.state.work.service_tasks_}</Text>
            <Text>Παροχη:{this.state.work.number_paroxi}</Text>
            <Text>Μετρητης:{this.state.work.number_metritis}</Text>
          </View>

          <View style={[styles.view, { padding: 0, margin: 0 }]}>
            <Text style={styles.textView}>General Info:</Text>
            <Text>Name: {this.state.work.name}</Text>
            <Text>CompanyId:{this.state.work.company_id}</Text>
            <Text>Status: {this.state.work.status}</Text>
            <Text>Description:{this.state.work.description}</Text>
            <Text>Notes:{this.state.work.notes}</Text>
            <Text>Entry User:{this.state.work.entryuser}</Text>
            <Text>Entry Date:{this.state.work.entrydate}</Text>
            <Text>Client:{this.state.work.client}</Text>
          </View>

          <View style={[styles.view, { zIndex: 0 }]}>
            <Text style={styles.textView}>Location Info:</Text>
            <Text>lat:{this.state.work.lat}</Text>
            <Text>long:{this.state.work.lng}</Text>
            <Text>Location:{this.state.work.location}</Text>
            <Text>Map:{this.state.work.map}</Text>
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100vh',
  },

  view: {
    backgroundColor: 'lightgray',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100vh',
  },

  textView: { fontWeight: 'bold', textDecorationLine: 'underline' },
});
