import React from 'react';
import { View, StyleSheet } from 'react-native-web';
import Form from '../../components/Form';

export default class Profile extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <View style={styles.container}>
        <Form />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '90vh',
    backgroundColor: '#bdbdbd',
  },
});
