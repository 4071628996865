import React, { Component } from 'react';
import { View, Text, TextInput, StyleSheet } from 'react-native-web';
import Api from '../../api/Api';
import Button from '../../components/Button';
import Table from '../../components/Table';
import SubHeader from '../../components/SubHeader';

class Users extends Component {
  state = {
    Users: [],
    searchInput: '',
    filteredUsers: [],
    formDisplay: true,
    passwordInput: '',
    name: '',
  };

  componentDidMount() {
    this.fetchUsers();
  }

  Toggle() {
    this.setState((currentState) => ({
      formDisplay: !currentState.formDisplay,
    }));
  }

  fetchUsers = () => {
    Api.get('admin/users')
      .then((res) => {
        let data = res.data.data;
        this.setState({ Users: data, filteredUsers: data });
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addUser = () => {
    Api.post('admin/users')
      .then((res) => {
        let data = res.data.data;
        this.state.Users.push(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  filterUsers = (text) => {
    this.setState({
      searchInput: text,
      filteredUsers: this.state.Users.filter((item) =>
        item.first_name.includes(text)
      ),
    });
  };

  render() {
    this.state.Users.map((item) => {
      return (item.button = (
        <Button
          style={{ width: '50%' }}
          onPress={() => this.props.history.push(`/admin/users/${item.id}`)}
        >
          <Text> View User </Text>
        </Button>
      ));
    });

    this.state.Users.map((item) => {
      return (item.editButton = (
        <Button
          style={{ width: '50%' }}
          onPress={() =>
            this.props.history.push(`/admin/users/${item.id}/edit`)
          }
        >
          <Text>Edit User</Text>
        </Button>
      ));
    });
    this.state.Users.map((item) => {
      return (item.deleteButton = (
        <Button onPress={() => this.deleteUser(item.id)} title="Delete User" />
      ));
    });

    return (
      <View style={styles.container}>
        <SubHeader title="Χρήστες" />
        <Button
          onPress={() => this.props.history.push('add')}
          style={styles.button}
        >
          <Text>Add new User</Text>
        </Button>
        <TextInput
          onChangeText={this.filterUsers}
          value={this.searchInput}
          placeholder="search for a client"
          style={styles.userInput}
        />
        <Table
          dataMap={{
            first_name: 'Όνομα',
            last_name: 'Επώνυμο',
            agent: 'Πάροχος',
            button: 'View',
            editButton: 'Edit',
            deleteButton: 'Delete',
          }}
          data={this.state.Users}
        />
      </View>
    );
  }
}

export default Users;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  userContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '20px',
  },
  textColor: {
    color: 'blue',
  },
  fontSize: {
    fontSize: '30px',
  },
  textUnderline: {
    borderBottomColor: 'blue',
    borderBottomWidth: 0.5,
    paddingRight: 180,
    borderStyle: 'dotted',
  },
  userInput: {
    padding: 10,
    borderRadius: 8,
    borderWidth: 0.5,
    borderColor: 'black',
    width: '20%',
  },

  button: { width: '20%', marginBottom: '10px' },
});
