import React from 'react';
import { View, Text, Picker } from 'react-native-web';

export default function PickerChoiceSelection(props) {
  return (
    <View>
      <Text>{props.displayTitle}</Text>
      {!props.viewMode ? (
        <Picker {...props}>
          {props.items.map((item) => (
            <Picker.Item
              key={item.key || item.value}
              value={item.value}
              label={item.label}
            />
          ))}
        </Picker>
      ) : (
        <Text>
          {props.value !== undefined && props.value !== null
            ? props.items.find((item) => item.value === props.value).label
            : null}
        </Text>
      )}
    </View>
  );
}
