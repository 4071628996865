import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native-web';
import InputLookUp from '../../components/InputLookUp';
import InputText from '../../components/InputText';
import { useRouteMatch } from 'react-router-dom';
import Api from '../../api/Api';
import Button from '../../components/Button';
import { TabView, Tab } from '../../components/Tabs';
import PickerChoiceSelection from '../../components/PickerChoiceSelection';
import SubHeader from '../../components/SubHeader';

export default function AddCompanyForm(props) {
  const [client, setClient] = useState();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [post_code, setPostalCode] = useState();
  const [country, setCountry] = useState();
  const [vat, setVat] = useState();
  const [tax_authority, setTaxAuthority] = useState();
  const [status, setStatus] = useState();
  const [stat_area, setStatArea] = useState();

  const [viewMode, setViewMode] = useState('view');

  let viewPath = useRouteMatch({
    path: '/admin/companies/:id',
    exact: true,
  });

  let editPath = useRouteMatch({
    path: '/admin/companies/:id/edit',
    exact: true,
  });
  let addPath = useRouteMatch({
    path: '/admin/companies/add',
    exact: true,
  });

  useEffect(() => {
    if (addPath) {
      setViewMode('add');
    } else if (editPath) {
      setViewMode('edit');
    } else if (viewPath) {
      setViewMode('view');
    }
  }, [addPath, editPath, viewPath]);

  let isView = viewMode === 'view';

  async function addCompany() {
    let res = await Api.post('/admin/companies', {
      client_id: client.id,
      name: name,
      address: address,
      city: city,
      post_code: post_code,
      country: country,
      vat: vat,
      tax_authority: tax_authority,
      status: status,
      stat_area: stat_area,
    });
    console.log(res);
  }

  const chooseSubHeader = () => {
    if (viewMode === 'view') {
      return <SubHeader title="Εταιρία" />;
    } else if (viewMode === 'add') {
      return <SubHeader title="Προσθέστε Εταιρία" />;
    } else if (viewMode === 'edit') {
      return <SubHeader title="Επεξεργασία Εταιρίας" />;
    }
  };

  async function editCompany() {
    await Api.put('/admin/companies/' + props.match.params.id, {
      client_id: client.id,
      name: name,
      address: address,
      city: city,
      post_code: post_code,
      country: country,
      vat: vat,
      tax_authority: tax_authority,
      status: status,
      stat_area: stat_area,
    });
  }

  useEffect(() => {
    const fetchCompany = async () => {
      const res = await Api.get('/admin/companies/' + props.match.params.id);
      let data = res.data.data;
      console.log(data);
      setClient(data.client);
      setName(data.name);
      setAddress(data.address);
      setCity(data.city);
      setPostalCode(data.post_code);
      setStatus(data.status);
      setStatArea(data.stat_area);
      setVat(data.vat);
      setTaxAuthority(data.tax_authority);
      setCountry(data.country);
    };
    if (props.match.params.id) {
      fetchCompany();
    }
  }, [props.match.params.id, viewMode]);

  function clearState() {
    setClient('');
    setName('');
    setAddress('');
    setCity('');
    setPostalCode('');
    setCountry('');
    setVat('');
    setTaxAuthority('');
    setStatus('');
    setStatArea('');
  }

  function renderButtons() {
    if (viewMode === 'view') {
      return null;
    }
    if (viewMode === 'add') {
      return (
        <View style={styles.buttonForm}>
          <Button
            onPress={() => addCompany()}
            style={styles.button}
            title={'Save'}
          />
          <Button
            onPress={() => clearState()}
            style={styles.button}
            title={'Cancel'}
          />
        </View>
      );
    } else {
      return (
        <View style={styles.buttonForm}>
          <Button
            onPress={() => editCompany()}
            style={styles.button}
            title={'Save'}
          />
          <Button
            onPress={() => clearState()}
            style={styles.button}
            title={'Cancel'}
          />
        </View>
      );
    }
  }
  return (
    <View style={styles.container}>
      {chooseSubHeader()}
      <InputLookUp
        viewMode={isView}
        displayTitle="Πελάτης"
        style={styles.inputText}
        value={client}
        displayKey="client"
        endpoint="/admin/client"
        searchQueryKey="search"
        onSelect={(item) => setClient(item)}
      >
        {client ? client.client : ''}
      </InputLookUp>
      <InputText
        viewMode={isView}
        displayTitle="Εταιρία"
        style={[styles.inputText, styles.margins]}
        value={name}
        onChangeText={(e) => setName(e)}
        multiline={true}
        placeholder="Add company"
      />
      <InputText
        viewMode={isView}
        displayTitle="Διεύθυνση"
        style={[styles.inputText, styles.margins]}
        value={address}
        onChangeText={(e) => setAddress(e)}
        multiline={true}
        placeholder="Add address"
      />
      <InputText
        viewMode={isView}
        displayTitle="Πόλη"
        style={[styles.inputText, styles.margins]}
        value={city}
        onChangeText={(e) => setCity(e)}
        multiline={true}
        placeholder="Add city"
      />
      <InputText
        viewMode={isView}
        displayTitle="Ταχ.Κωδ."
        style={[styles.inputText, styles.margins]}
        value={post_code}
        onChangeText={(e) => setPostalCode(e)}
        multiline={true}
        placeholder="Add postal_code"
      />
      <InputText
        viewMode={isView}
        displayTitle="Χώρα"
        style={[styles.inputText, styles.margins]}
        value={country}
        onChangeText={(e) => setCountry(e)}
        multiline={true}
        placeholder="Add country"
      />
      <InputText
        viewMode={isView}
        displayTitle="Α.Φ.Μ"
        style={[styles.inputText, styles.margins]}
        value={vat}
        onChangeText={(e) => setVat(e)}
        multiline={true}
        placeholder="Add vat"
      />
      <InputText
        viewMode={isView}
        displayTitle="Εφορία"
        style={[styles.inputText, styles.margins]}
        value={tax_authority}
        onChangeText={(e) => setTaxAuthority(e)}
        multiline={true}
        placeholder="Add tax authority"
      />
      <InputText
        viewMode={isView}
        displayTitle="Στατ. Περιοχή"
        style={[styles.inputText, styles.margins]}
        value={stat_area}
        onChangeText={(e) => setStatArea(e)}
        multiline={true}
        placeholder="Add tax authority"
      />

      <PickerChoiceSelection
        items={[
          { value: 0, label: 'Disabled', key: 0 },
          { value: 1, label: 'Enabled', key: 1 },
        ]}
        selectedValue={status}
        onValueChange={(item) => setStatus(item)}
        displayTitle="Κατάσταση"
        viewMode={isView}
        value={status}
      />
      <View style={{ marginTop: 50 }}>
        <TabView>
          <Tab title="Επαφές" />
        </TabView>
      </View>
      {renderButtons()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '20px',
  },
  buttonForm: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },

  button: {
    width: 120,
    borderRadius: 5,
    alignSelf: 'flex-end',
    marginLeft: 15,
    marginTop: 25,
  },

  margins: {
    marginLeft: 10,
    marginBottom: 15,
  },
});
