import React, { useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import Button from '../components/Button';
import { useHistory } from 'react-router-dom';
import cookie from 'react-cookies';
import Notifications from '../pages/notifications';

// eslint-disable-next-line no-sparse-arrays
const DATA = [
  { id: 1, name: 'Main Dashboard', path: '/admin/dashboard' },
  { id: 2, name: 'Analytics', path: '/admin/analytics' },
  { id: 3, name: 'Πελάτες', path: '/admin/clients' },
  { id: 4, name: 'Εταιρίες', path: '/admin/companies' },
  { id: 5, name: 'Εγκαταστάσεις', path: '/admin/sites' },
  { id: 6, name: 'Συντήρηση', path: '/admin/works' },
  { id: 7, name: 'Τεχνικοί', path: '/admin/staff' },
  { id: 8, name: 'Υλικά', path: '/admin/materials' },
];

// eslint-disable-next-line no-sparse-arrays
const administrationData = [
  { id: 10, name: 'Χρήστες', path: '/admin/Users' },
  { id: 20, name: 'Δικαιώματα Χρηστών', path: '/admin/roles' },
  { id: 30, name: 'Συμβόλαια', path: '/admin/contracts' },
  { id: 40, name: 'Υπηρεσίες', path: '/admin/services' },
  { id: 50, name: 'Κατηγορίες Υλικών', path: '/admin/materialCategories' },
  { id: 60, name: 'Κατασκευαστές/Προμηθευτές', path: '/admin/sellers' },
  { id: 70, name: 'Κατηγορίες Εγκαταστάσεων', path: '/admin/sitesCategories' },
  {
    id: 80,
    name: 'Service Tasks Templates',
    path: '/admin/serviceTaskTemplates',
  },
  { id: 90, name: 'Tasks', path: '/admin/tasks' },
  { id: 100, name: 'Task Categories', path: '/admin/taskCategories' },
  { id: 110, name: 'Αρχείο Αλλαγών', path: '/admin/changes' },
];

function Item({ item }) {
  let history = useHistory();
  return (
    <TouchableOpacity
      style={styles.item}
      onPress={() => history.push(item.path)}
    >
      <Text>{item.name}</Text>
    </TouchableOpacity>
  );
}

const AdminLayout = (props) => {
  if (!cookie.load('token')) {
    window.location.replace('/login');
  }

  const [toggleState, setToggleState] = useState(true);
  const [toggleAdministrationState, setToggleAdministrationState] = useState(
    true
  );

  function toggle() {
    setToggleState(!toggleState);
  }

  function toggleAdministration() {
    setToggleAdministrationState(!toggleAdministrationState);
  }

  function renderAdministrationChoices() {
    return (
      <FlatList
        keyExtractor={(item) => item.id.toString()}
        data={administrationData}
        renderItem={({ item }) => <Item item={item} />}
        style={styles.list}
      />
    );
  }

  return (
    <View>
      <View style={styles.rowDirection}>
        <View style={[styles.container, { flexBasis: toggleState ? 200 : 0 }]}>
          <ScrollView style={{ height: 10 }}>
            <FlatList
              keyExtractor={(item) => item.id.toString()}
              data={DATA}
              renderItem={({ item }) => <Item item={item} />}
              style={styles.listAlign}
            />

            <TouchableOpacity
              style={styles.item}
              onPress={() => toggleAdministration()}
            >
              <Text style={styles.administration}>Administration</Text>
            </TouchableOpacity>
            {!toggleAdministrationState ? renderAdministrationChoices() : null}
          </ScrollView>
        </View>

        <View style={{ flex: 1 }}>
          <View>
            <View style={styles.view}>
              <Button
                onPress={toggle}
                style={[styles.close, { backgroundColor: '#eeeeee' }]}
              >
                <Text>X</Text>
              </Button>
              <View style={styles.headerIcons}>
                <Text>Profile-Icon {'   '}</Text>
                <Notifications />
              </View>
            </View>
          </View>
          {props.children}
        </View>
      </View>
    </View>
  );
};

export default AdminLayout;

const styles = StyleSheet.create({
  view: {
    flex: 1,
    backgroundColor: '#eeeeee',
    padding: '25px',
    alignItems: 'flex-end',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },

    shadowOpacity: 0.29,
    shadowRadius: 4.65,
    elevation: 25,
    width: '100',
  },

  text: {
    fontSize: '15px',
    wordSpacing: '20px',
  },

  close: {
    left: '50px',
    top: '15px',
    position: 'absolute',
    padding: '10px',
    backgroundColor: 'lightgray',
  },
  container: {
    marginTop: 80,
    flexDirection: 'column',
    flexBasis: '10%',
    alignSelf: 'flexStart',
  },

  item: {
    paddingVertical: 20,
    margin: 2,
    width: '100%',
    color: 'white',
  },

  list: {
    marginLeft: 45,
    color: 'white',
  },

  dropVisible: {
    position: 'absolute',
    backgroundColor: 'white',
    height: 100,
    width: 100,
    top: 100,
  },

  administration: {
    marginLeft: 35,
    marginBottom: 15,
  },

  rowDirection: { flexDirection: 'row' },

  listAlign: { alignSelf: 'center', color: 'white' },

  headerIcons: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
