import {
  FETCH_SITES_FAILURE,
  FETCH_SITES_REQUEST,
  FETCH_SITES_SUCCESS,
} from '../types/sites';
import Api from '../../api/Api';

export const fetchSitesRequest = () => {
  return {
    type: FETCH_SITES_REQUEST,
  };
};
export const fetchSitesSuccess = (sites) => {
  return {
    type: FETCH_SITES_SUCCESS,
    payload: sites,
  };
};
export const fetchSiteFailure = (error) => {
  return {
    type: FETCH_SITES_FAILURE,
    payload: error,
  };
};

export const fetchSites = () => {
  return function (dispatch) {
    dispatch(fetchSitesRequest());
    Api.get('client/sites')
      .then((res) => {
        const data = res.data.data;
        dispatch(fetchSitesSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
