import {
  FETCH_NOTIFICATIONS_FAILURE,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
} from '../types/notifications';

const initialState = {
  loading: false,
  notifications: [],
  page: 0,
  error: '',
};

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        loading: false,
        notifications:
          action.page !== 0
            ? state.notifications.concat(action.payload)
            : action.payload,
        page: action.page,
        error: '',
      };
    case FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
