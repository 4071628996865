import React, { Component } from 'react';
import {
  View,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  FlatList,
  ScrollView,
} from 'react-native-web';
import Button from './Button';
import { connect } from 'react-redux';
import * as notesActions from '../redux/actions/notes';
import Modal from 'modal-enhanced-react-native-web';
import CardBlock from './CardBlock';

const colors = ['#AEBD38', '#598234', '#68829E', '#5bc8ac'];

class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formDisplay: true,
      textNote: '',
      selectedColor: '',
      visibleModal: false,
      editNote: {
        id: null,
        color_hex: '',
        note: '',
      },
    };
  }

  componentDidMount() {
    this.props.fetchNotes(0);
  }

  Toggle() {
    this.setState((currentState) => ({
      formDisplay: !currentState.formDisplay,
    }));
  }

  handleText = (text) => {
    this.setState({
      textNote: text,
    });
  };

  loadMoreNotes = () => {
    this.props.fetchNotes();
  };

  renderModal = () => {
    return (
      <Modal isVisible={this.state.visibleModal}>
        {this.renderModalContent()}
      </Modal>
    );
  };

  renderModalContent = (item) => {
    return (
      <CardBlock displayTitle="Edit Note">
        <TextInput
          value={this.state.editNote.note}
          onChangeText={(e) =>
            this.setState((prevState) => ({
              editNote: {
                ...prevState.editNote,
                note: e,
              },
            }))
          }
        />
        {this.renderColorChoice()}
        <Button
          title="Close"
          onPress={() => this.setState({ visibleModal: null })}
        />
        <Button
          title="Save Changes"
          onPress={() => this.props.editNotes(this.state.editNote)}
        />
      </CardBlock>
    );
  };

  renderForm = () => {
    return (
      <View style={styles.renderFormView}>
        <TextInput
          onChangeText={this.handleText}
          value={this.state.textNote}
          multiline={true}
          style={styles.noteInputBox}
          placeholder="Add note"
        />
        <View>{this.renderColorChoice()}</View>
        <Button
          onPress={() =>
            this.props.addNotes({
              note: this.state.textNote,
              color_hex: this.state.selectedColor,
            })
          }
          style={styles.addNote}
          title="Add note"
        />
      </View>
    );
  };

  renderNotes = (item) => {
    return (
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <TouchableOpacity
          style={[styles.notes, { backgroundColor: item.color_hex }]}
          onPress={() =>
            this.setState({
              visibleModal: true,
              editNote: {
                id: item.note_id,
                color_hex: item.color_hex,
                note: item.note,
              },
            })
          }
        >
          {item.note}
          <TouchableOpacity
            onPress={() => this.props.deleteNotes(item.note_id)}
            style={styles.deleteButton}
          >
            <Text style={styles.deleteNoteText}>delete</Text>
          </TouchableOpacity>
        </TouchableOpacity>
      </View>
    );
  };

  renderColorChoice = () => {
    return (
      <View>
        <View style={styles.renderColorView}>
          <Text style={{ marginTop: '10px' }}>Colors</Text>``
          {colors.map((item, i) => {
            let opacity = item === this.state.selectedColor ? 4 : 0;
            return (
              <TouchableOpacity
                onPress={() => this.setState({ selectedColor: item })}
                style={[
                  styles.box,
                  { backgroundColor: colors[i], borderWidth: opacity },
                ]}
              />
            );
          })}
        </View>
      </View>
    );
  };

  render() {
    return (
      <View>
        <Button onPress={() => this.Toggle()} style={styles.addNote}>
          {this.state.formDisplay ? (
            <Text style={styles.buttonText}>Add new note</Text>
          ) : (
            <Text style={styles.buttonText}>Close notes</Text>
          )}
        </Button>

        {!this.state.formDisplay && this.renderForm()}
        <ScrollView style={{ height: 400 }}>
          <FlatList
            data={this.props.notes}
            renderItem={({ item }) => this.renderNotes(item)}
          />
        </ScrollView>
        <Modal isVisible={this.state.visibleModal}>
          {this.renderModalContent()}
        </Modal>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.notesReducer });

export default connect(mapStateToProps, notesActions)(Notes);

const styles = StyleSheet.create({
  addNote: {
    borderWidth: 1,
    alignSelf: 'center',
    width: '25%',
    background: '#009688',
    cursor: 'pointer',
    elevation: 8,
    paddingVertical: 10,
    paddingHorizontal: 12,
    borderRadius: 10,
  },

  noteInputBox: {
    padding: '20px',
    borderRadius: '20px',
    borderWidth: 1,
    width: '80%',
    alignSelf: 'center',
  },

  box: {
    height: '30px',
    width: '30px',
    left: '5px',
    flexDirection: 'row',
  },

  notesContainer: { backgroundColor: '#F5F5F5' },

  notes: { margin: '20px', padding: '40px' },

  renderFormView: { justifyContent: 'center' },

  renderColorView: { flex: 1, flexDirection: 'row', alignSelf: 'center' },

  buttonText: {
    fontSize: 14,
    color: '#fff',
    textTransform: 'uppercase',
  },

  deleteNoteText: {
    fontSize: 16,
    letterSpacing: 1,
    textTransform: 'uppercase',
    textAlign: 'center',
    textDecorationLine: 'underline',
    textDecorationColor: 'red',
    textDecorationStyle: 'solid',
  },

  deleteButton: {
    border: 'none',
    flex: 1,
    alignSelf: 'flex-end',
    bottom: 55,
    left: 35,
  },
});
