import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, Picker } from 'react-native-web';
import InputLookUp from '../../components/InputLookUp';
import { useRouteMatch } from 'react-router-dom';
import Api from '../../api/Api';
import Button from '../../components/Button';
import { TabView, Tab } from '../../components/Tabs';
import InputForm from '../../components/InputText';
import AssignDate from '../../components/AssignDate';
import moment from 'moment';
import SubHeader from '../../components/SubHeader';
import WorkTasksForm from '../../components/WorkTasksForm';

export default function WorksForm(props) {
  const [client, setClient] = useState();
  const [company, setCompany] = useState();
  const [site, setSite] = useState();
  const [cause, setCause] = useState();
  const [severity, setSeverity] = useState();
  const [status, setStatus] = useState(1);
  const [dateAnnounced, setDateAnnounced] = useState();
  const [dateStaffInformed, setDateStaffInformed] = useState();
  const [dateStaffAcknowledged, setDateStaffAcknowledged] = useState();
  const [dateStarted, setDateStarted] = useState();
  const [dateVisited, setDateVisited] = useState();
  const [dateResolved, setDateResolved] = useState();
  const [descriptionComment, setDescriptionComment] = useState();
  const [privateStaffComment, setPrivateStaffComment] = useState();
  const [adminComment, setAdminComment] = useState();
  const [staffComment, setStaffComment] = useState();
  const [partsComment, setPartsComment] = useState();
  const [commentToStaff, setCommentToStaff] = useState();
  const [privateAdminComment, setPrivateAdminComment] = useState();
  const [result, setResult] = useState(1);
  const [excludeFromDownTime, setExcludeFromDownTime] = useState(1);
  const [excludeFromReporting, setExcludeFromReporting] = useState(1);
  const [priority, setPriority] = useState(1);

  const [viewMode, setViewMode] = useState('view');

  let viewPath = useRouteMatch({
    path: '/admin/works/:id',
    exact: true,
  });

  let editPath = useRouteMatch({
    path: '/admin/works/:id/edit',
    exact: true,
  });
  let addPath = useRouteMatch({
    path: '/admin/works/add',
    exact: true,
  });

  useEffect(() => {
    if (addPath) {
      setViewMode('add');
    } else if (editPath) {
      setViewMode('edit');
    } else if (viewPath) {
      setViewMode('view');
    }
  }, [addPath, editPath, viewPath]);

  let isView = viewMode === 'view';

  function clearState() {}

  function renderButtons() {
    if (viewMode === 'view') {
      return null;
    }
    if (viewMode === 'add') {
      return (
        <View style={styles.buttonForm}>
          <Button
            onPress={() => addWork()}
            style={styles.button}
            title={'Save'}
          />
          <Button
            onPress={() => clearState()}
            style={styles.button}
            title={'Cancel'}
          />
        </View>
      );
    } else {
      return (
        <View style={styles.buttonForm}>
          <Button
            onPress={() => editWork()}
            style={styles.button}
            title={'Save'}
          />
          <Button
            onPress={() => clearState()}
            style={styles.button}
            title={'Cancel'}
          />
        </View>
      );
    }
  }

  const chooseSubHeader = () => {
    if (viewMode === 'view') {
      return <SubHeader title="Εργασία" />;
    } else if (viewMode === 'add') {
      return <SubHeader title="Προσθέστε Εργασία" />;
    } else if (viewMode === 'edit') {
      return <SubHeader title="Επεξεργασία Εργασίας" />;
    }
  };

  useEffect(() => {
    const fetchWork = async () => {
      const res = await Api.get('/admin/works/' + props.match.params.id);
      let data = res.data.data;

      setClient(data.client);
      setCompany(data.company_name);
      setSite(data.site_name);
      setCause(data.cause_name);
      setSeverity(data.severity);
      setStatus(data.status);
      setPriority(data.priority);
      setDateAnnounced(moment(data.date_announced));
      setDateStaffInformed(moment(data.date_staff_informed));
      setDateStaffAcknowledged(moment(data.date_staff_acknowledged));
      setDateStarted(moment(data.date_started));
      setDateVisited(moment(data.date_visited));
      setDateResolved(moment(data.date_resolved));
      setDescriptionComment(data.description_comment);
      setAdminComment(data.admin_comment);
      setStaffComment(data.staff_comment);
      setPartsComment(data.parts_comment);
      setCommentToStaff(data.comment_to_staff);
      setPrivateAdminComment(data.private_admin_comment);
      setResult(data.result);
      setExcludeFromDownTime(data.exclude_from_down_time);
      setExcludeFromReporting(data.exclude_from_reporting);
      if (props.match.params.id) {
        fetchWork();
      }
    };
  }, [props.match.params.id]);

  async function addWork() {
    let res = await Api.post('/admin/works', {
      client_id: client.id,
      company_id: company.id,
      site_id: site.id,
      cause_id: cause.id,
      severity: severity,
      status: status,
      priority: priority,
      date_announced: moment(dateAnnounced).format('YYYY-MM-DDTHH:mm'),

      date_staff_informed: moment(dateStaffInformed).format('YYYY-MM-DDTHH:mm'),
      date_started: moment(dateStarted).format('YYYY-MM-DDTHH:mm'),
      date_visited: moment(dateVisited).format('YYYY-MM-DDTHH:mm'),
      date_resolved: moment(dateResolved).format('YYYY-MM-DDTHH:mm'),
      date_staff_acknowledged: moment(dateStaffAcknowledged).format(
        'YYYY-MM-DDTHH:mm'
      ),
      description_comment: descriptionComment,
      admin_comment: adminComment,
      staff_comment: staffComment,
      parts_comment: partsComment,
      private_staff_comment: privateStaffComment,
      private_admin_comment: privateAdminComment,
      comment_to_staff: commentToStaff,
      result: result,
      exclude_from_down_time: excludeFromDownTime,
      exclude_from_reporting: excludeFromReporting,
    });
  }

  async function editWork() {
    let res = await Api.put('/admin/works/' + props.match.params.id, {
      client_id: client.id,
      company_id: company.id,
      site_id: site.id,
      cause_id: cause.id,
      severity: severity,
      priority: priority,
      status: status,
      date_announced: moment(dateAnnounced).format('YYYY-MM-DDTHH:mm'),

      date_staff_informed: moment(dateStaffInformed).format('YYYY-MM-DDTHH:mm'),
      date_started: moment(dateStarted).format('YYYY-MM-DDTHH:mm'),
      date_visited: moment(dateVisited).format('YYYY-MM-DDTHH:mm'),
      date_resolved: moment(dateResolved).format('YYYY-MM-DDTHH:mm'),
      date_staff_acknowledged: moment(dateStaffAcknowledged).format(
        'YYYY-MM-DDTHH:mm'
      ),
      description_comment: descriptionComment,
      admin_comment: adminComment,
      staff_comment: staffComment,
      parts_comment: partsComment,
      private_staff_comment: privateStaffComment,
      private_admin_comment: privateAdminComment,
      comment_to_staff: commentToStaff,
      result: result,
      exclude_from_down_time: excludeFromDownTime,
      exclude_from_reporting: excludeFromReporting,
    });
  }

  return (
    <View style={styles.container}>
      {chooseSubHeader()}
      <InputLookUp
        viewMode={isView}
        displayTitle="Πελάτης"
        displayKey="client"
        endpoint="/admin/clients"
        searchQueryKey="search"
        onSelect={(item) => setClient(item)}
        value={client}
      >
        {client ? client.client : ''}
      </InputLookUp>

      <InputLookUp
        viewMode={isView}
        displayTitle="Εταιρία"
        value={company}
        displayKey="name"
        endpoint="/admin/companies"
        searchQueryKey="search"
        onSelect={(item) => setCompany(item)}
      >
        {company ? company.name : ''}
      </InputLookUp>

      <InputLookUp
        viewMode={isView}
        displayTitle="Εγκατάσταση"
        value={site}
        displayKey="name"
        endpoint="/admin/sites"
        searchQueryKey="search"
        onSelect={(item) => setSite(item)}
      >
        {site ? site.name : ''}
      </InputLookUp>

      <InputLookUp
        viewMode={isView}
        displayTitle="Αιτία-Βλάβη"
        value={cause}
        displayKey="name"
        endpoint="/admin/cause"
        searchQueryKey="search"
        onSelect={(item) => setCause(item)}
      >
        {cause ? cause.name : ''}
      </InputLookUp>

      <View style={styles.content}>
        <Text style={styles.textForm}>Κατάσταση</Text>
        <Picker
          selectedValue={status}
          onValueChange={(itemValue, itemIndex) => setStatus(itemValue)}
          style={styles.pickerWidth}
        >
          <Picker.Item label="Νέο" value={1} />
          <Picker.Item label="Σε εξέλιξη" value={2} />
          <Picker.Item label="Έκλεισε" value={3} />
          <Picker.Item label="Ακυρώθηκε" value={4} />
        </Picker>
      </View>
      <View style={styles.content}>
        <Text style={styles.textForm}>Βαρύτητα Βλάβης</Text>
        <Picker
          selectedValue={severity}
          onValueChange={(itemValue, itemIndex) => setSeverity(itemValue)}
          style={styles.pickerWidth}
        >
          <Picker.Item label="MAJOR" value={1} />
          <Picker.Item label="NON PRODUCATION" value={2} />
          <Picker.Item label="MINOR" value={3} />
        </Picker>
      </View>
      <View style={styles.content}>
        <Text style={styles.textForm}>Προτεραιότητα</Text>
        <Picker
          selectedValue={priority}
          onValueChange={(itemValue, itemIndex) => setPriority(itemValue)}
          style={styles.pickerWidth}
        >
          <Picker.Item label="LOW" value={1} />
          <Picker.Item label="MEDIUM" value={2} />
          <Picker.Item label="HIGH" value={3} />
        </Picker>
      </View>
      <View style={styles.TabViewContent}>
        <TabView>
          <Tab title="Βασικά Στοιχεία">
            <AssignDate
              displayTitle={'Αναγγελία Βλάβης'}
              onDateChange={(date) => setDateAnnounced(date)}
              date={dateAnnounced}
              id={'1'}
            />
            <AssignDate
              displayTitle={'Ενημέρωση Τεχνικού'}
              onDateChange={(date) => setDateStaffInformed(date)}
              date={dateStaffInformed}
              id={'2'}
            />
            <AssignDate
              displayTitle={'Επιβεβαίωση Τεχνικού'}
              onDateChange={(date) => setDateStaffAcknowledged(date)}
              date={dateStaffAcknowledged}
              id={'3'}
            />
            <AssignDate
              displayTitle={'Έναρξη'}
              onDateChange={(date) => setDateStarted(date)}
              date={dateStarted}
              id={'4'}
            />
            <View>
              <InputForm
                displayTitle={'Οδηγίες προς τεχνικό'}
                onChangeText={(text) => setCommentToStaff(text)}
                value={commentToStaff}
              />
            </View>
            <AssignDate
              displayTitle={'Eπίσκεψη Τεχνικού'}
              onDateChange={(date) => setDateVisited(date)}
              date={dateVisited}
              id={'5'}
            />
            <AssignDate
              displayTitle={'Ολοκλήρωση'}
              onDateChange={(date) => setDateResolved(date)}
              date={dateResolved}
              id={'6'}
            />
          </Tab>
          <Tab title="Παρατηρησεις Διαχειριστή">
            <InputForm displayTitle="Περιγραφή Βλάβης" placeholder="Admin" />
            <InputForm displayTitle="Παρατηρήσεις Διαχ" placeholder="Admin" />
            <InputForm
              displayTitle="Παρατηρήσεις Admin"
              placeholder="Admin"
              value={adminComment}
              onChangeText={(text) => setAdminComment(text)}
            />
            <InputForm
              displayTitle="Παρατηρήσεις Διαχειριστή (admin use only)"
              placeholder="Admin"
              value={privateAdminComment}
              onChangeText={(text) => setPrivateAdminComment(text)}
            />
            <InputForm
              displayTitle="Παρατηρήσεις Υλικών"
              placeholder="Admin"
              value={partsComment}
              onChangeText={(text) => setPartsComment(text)}
            />
          </Tab>
          <Tab title="Παρατηρήσεις Τεχνικού">
            <InputForm
              displayTitle="Παρατηρήσεις Τεχνικού σε Διαχ"
              placeholder="Admin"
              value={staffComment}
              onChangeText={(text) => setStaffComment(text)}
            />
            <InputForm
              displayTitle="Παρατηρήσεις Τεχνικού (staff use only)"
              placeholder="Admin"
              value={privateStaffComment}
              onChangeText={(text) => setPrivateStaffComment(text)}
            />
          </Tab>
          <Tab title="Αποτέλεσμα">
            <View style={styles.content}>
              <Text style={styles.textForm}>Αποτελεσμα</Text>
              <View style={styles.row}>
                <Picker
                  style={styles.pickerWidth}
                  selectedValue={result}
                  onValueChange={setResult}
                >
                  <Picker.Item label="Οκ" value={1} />
                  <Picker.Item label="Μη επιλυση" value={2} />
                  <Picker.Item label="Αλλο" value={3} />
                </Picker>
              </View>
            </View>
            <View style={styles.content}>
              <Text style={styles.textForm}>Εξαίρεση απο down time</Text>
              <View style={styles.row}>
                <Picker
                  style={styles.pickerWidth}
                  selectedValue={excludeFromDownTime}
                  onValueChange={setExcludeFromDownTime}
                >
                  <Picker.Item label="Ναι" value={1} />
                  <Picker.Item label="Οχι" value={2} />
                </Picker>
              </View>
            </View>
            <View style={styles.content}>
              <Text style={styles.textForm}>Εξαίρεση απο reporting</Text>
              <View style={styles.row}>
                <Picker
                  style={styles.pickerWidth}
                  selectedValue={excludeFromReporting}
                  onValueChange={setExcludeFromReporting}
                >
                  <Picker.Item label="Ναι" value={1} />
                  <Picker.Item label="Οχι" value={2} />
                </Picker>
              </View>
            </View>
          </Tab>
        </TabView>
      </View>
      <WorkTasksForm viewMode={isView} workId={props.match.params.id} />
      <TabView style={{ marginTop: 20 }}>
        <Tab title="Ανταλλακτικά" />
        <Tab title="Υπηρεσίες/Χρεώσεις" />
        <Tab title="Φωτογραφίες" />
        <Tab title="Χρεώσεις Τεχνικών" />
      </TabView>
      {renderButtons()}
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    alignSelf: 'flexStart',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  text: {
    fontSize: 24,
    color: 'blue',
  },

  input: {
    padding: 10,
    borderWidth: 1,
    borderRadius: '5%',
    width: '20%',
    alignSelf: 'flex-start',
  },

  lookups: {
    flex: 1,
  },

  pickerWidth: {
    height: 50,
    width: 150,
  },

  TabViewContent: { borderWidth: 1, marginTop: 20 },

  buttonForm: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },

  button: {
    width: 120,
    borderRadius: 5,
    alignSelf: 'flex-end',
    marginLeft: 15,
    marginTop: 25,
  },
});
