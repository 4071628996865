import React, { useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import Button from '../components/Button';
import { useHistory } from 'react-router-dom';
import cookie from 'react-cookies';

const DATA = [
  { id: 1, name: 'Dashboard', path: '/dashboard' },
  { id: 2, name: 'Analytics', path: '/analytics' },
  { id: 3, name: 'Statistics', path: '/statistics' },
  { id: 4, name: 'Sites', path: '/sites' },
  { id: 5, name: 'Work', path: '/works' },
  { id: 6, name: 'Profile', path: '/profile' },
];

function Item({ item }) {
  let history = useHistory();
  return (
    <TouchableOpacity
      style={styles.item}
      onPress={() => history.push(item.path)}
    >
      <Text>{item.name}</Text>
    </TouchableOpacity>
  );
}

const Layout = (props) => {
  if (!cookie.load('token')) {
    window.location.replace('/login');
  }

  const [toggleState, setToggleState] = useState(true);

  function toggle() {
    setToggleState(!toggleState);
  }

  return (
    <View>
      <View style={{ flexDirection: 'row' }}>
        <View style={[styles.container, { width: toggleState ? 200 : 0 }]}>
          <FlatList
            keyExtractor={(item) => item.id.toString()}
            data={DATA}
            renderItem={({ item }) => <Item item={item} />}
            style={{ alignSelf: 'center' }}
          />
        </View>
        <View style={{ flex: 1 }}>
          <View>
            <View style={styles.view}>
              <Button
                onPress={toggle}
                style={[styles.close, { backgroundColor: '#eeeeee' }]}
              >
                <Text>X</Text>
              </Button>
              <Text style={styles.text}>Support English</Text>
            </View>
          </View>
          {props.children}
        </View>
      </View>
    </View>
  );
};

export default Layout;

const styles = StyleSheet.create({
  view: {
    flex: 1,
    backgroundColor: '#eeeeee',
    padding: '25px',
    alignItems: 'flex-end',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },

    shadowOpacity: 0.29,
    shadowRadius: 4.65,
    elevation: 25,
    width: '100',
  },

  text: {
    fontSize: '15px',
    wordSpacing: '20px',
  },

  close: {
    left: '50px',
    top: '15px',
    position: 'absolute',
    padding: '10px',
    backgroundColor: 'lightgray',
  },
  container: {
    width: 100,
    paddingTop: 100,
    backgroundColor: 'white',
  },

  item: {
    alignSelf: 'Center',
    backgroundColor: 'white',
    paddingVertical: 20,
    margin: 2,
    width: '100%',
  },

  dropVisible: {
    position: 'absolute',
    backgroundColor: 'white',
    height: 100,
    width: 100,
    top: 100,
  },
});
