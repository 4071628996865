import { combineReducers } from 'redux';
import { sitesReducer } from '../reducers/sites';
import { notificationsReducer } from '../reducers/notifications';
import { companyReducer } from '../reducers/company';
import { notesReducer } from '../reducers/notes';
import { causeReducer } from '../reducers/cause';
import { worksReducer } from '../reducers/works';
import { accountReducer } from '../reducers/account';

export const rootReducer = combineReducers({
  sitesReducer,
  notificationsReducer,
  companyReducer,
  notesReducer,
  causeReducer,
  worksReducer,
  accountReducer,
});
