import React, { Component } from 'react';
import { View, StyleSheet, Text } from 'react-native-web';
import Button from '../../components/Button';
import Api from '../../api/Api';
import InputLookUp from '../../components/InputLookUp';
import Table from '../../components/Table';
import { withRouter } from 'react-router-dom';
import SubHeader from '../../components/SubHeader';

class AdminSites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      clientData: [],
      filters: {},
      displayPanel: false,
      filteredSites: [],
    };
  }

  fetchSites = () => {
    Api.get('/admin/sites', {
      params: {
        client_id: this.state.filters.clientId,
      },
    })
      .then((res) => {
        let data = res.data.data;
        console.log(data);
        this.setState({ data: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.fetchSites();
  }

  clientSelected = (item) => {
    this.setState(
      { filters: { ...this.state.filters, clientId: item.id } },
      () => {
        this.fetchSites();
      }
    );
  };

  agentSelected = (item) => {
    this.setState({
      filters: { ...this.state.filters, agentName: item.agent },
    });
  };

  companySelected = (item) => {
    this.setState({
      filters: { ...this.state.filters, companyName: item.name },
    });
  };

  toggle = () => {
    this.setState({
      displayPanel: !this.state.displayPanel,
    });
  };

  deleteSite = async (id) => {
    await Api.delete('admin/sites/' + id);
    console.log('deleted item with id ' + id);
  };

  renderSearchPanel = () => {
    return (
      <View>
        <InputLookUp
          displayTitle={'Πελάτης'}
          displayKey="agent"
          endpoint="admin/agents"
          searchQueryKey="search"
          onSelect={this.agentSelected}
        >
          {this.state.filters.agentName}
        </InputLookUp>

        <InputLookUp
          displayTitle={'Εταιρία'}
          displayKey="name"
          endpoint="admin/companies"
          searchQueryKey="search"
          onSelect={this.companySelected}
        >
          {this.state.filters.companyName}
        </InputLookUp>

        <InputLookUp displayTitle="Ανάθεση σε συνεργάτη" />
      </View>
    );
  };

  isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  };

  render() {
    let filterSites = this.isEmpty(this.state.filters)
      ? this.state.data
      : this.state.filteredSites;

    this.state.data.map((item) => {
      return (item.button = (
        <Button
          onPress={() => this.props.history.push(`/admin/sites/${item.id}`)}
        >
          <Text> View Site </Text>
        </Button>
      ));
    });

    this.state.data.map((item) => {
      return (item.editButton = (
        <Button
          onPress={() => this.props.history.push(`sites/${item.id}/edit`)}
        >
          <Text>Edit Site</Text>
        </Button>
      ));
    });

    this.state.data.map((item) => {
      item.deleteButton = (
        <Button onPress={() => this.deleteSite(item.id)} title="Delete" />
      );
      return item;
    });

    return (
      <View style={styles.container}>
        <View>
          <SubHeader title="Εγκαταστάσεις" />
          <Button
            style={styles.buttonWidthAndRadius}
            onPress={() => this.toggle()}
          >
            <Text>Close Panel</Text>
          </Button>
          {!this.state.displayPanel && this.renderSearchPanel()}
        </View>
        <Button
          style={[styles.buttonWidthAndRadius, styles.buttonMargins]}
          onPress={() => this.props.history.push('/admin/sites/add')}
        >
          <Text>Add New Site</Text>
        </Button>
        <Table
          data={filterSites}
          dataMap={{
            name: 'Εγκατασταση',
            power: 'Ισχύς',
            agent: 'Πάροχος',
            company_name: 'Εταιρία',
            number_paroxi: 'Αριθμός Παροχής',
            button: '',
            editButton: '',
            deleteButton: '',
          }}
          rowStyle={styles.rowStyles}
        />
      </View>
    );
  }
}

export default withRouter(AdminSites);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  sitesContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '20px',
  },
  textColor: {
    color: 'blue',
  },
  fontSize: {
    fontSize: '30px',
  },
  textUnderline: {
    borderBottomColor: 'blue',
    borderBottomWidth: 0.5,
    paddingRight: 180,
    borderStyle: 'dotted',
  },

  lookUp: {
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  clientText: {
    paddingRight: 110,
    fontSize: '16px',
    color: 'gray',
    marginBottom: 5,
  },

  clientInput: {
    padding: 10,
    borderRadius: 8,
    borderWidth: 0.5,
    borderColor: 'black',
  },
  rowStyles: {
    backgroundColor: '#a4bfeb',
    marginBottom: 15,
    textTransform: 'uppercase',
  },

  buttonWidthAndRadius: { width: 200, borderRadius: 5 },
  buttonMargins: { marginTop: 15, marginLeft: 5 },
});
