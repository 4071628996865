import React, { Component } from 'react';
import { View, StyleSheet, Text, CheckBox } from 'react-native-web';
import { TabView, Tab } from './Tabs';
import Api from '../api/Api';
import CardBlock from './CardBlock';
import Table from './Table';
import PickerChoiceSelection from './PickerChoiceSelection';
import InputText from './InputText';
import Button from './Button';

class WorkTasksForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      status: [],
      checked: false,
      tasks: [],
      adminAction: '',
      adminNotes: '',
      staffAction: '',
      staffNotes: '',
      pickerValue: null,
      viewMode: this.props.viewMode,
    };
  }

  componentDidMount() {
    this.fetchTasks();
  }

  handleCheckBox = () => {
    this.setState({ checked: !this.state.checked });
  };

  handleAdminAction = (text, taskId) => {
    console.log(text, taskId);
    const newArray = [...this.state.tasks];
    let index = newArray.findIndex((item) => (item.task_id = taskId));
    newArray[index].admin_actions = text;
    this.setState({ tasks: newArray });
  };

  handleAdminNotes = (text, index) => {
    const newArray = [...this.state.tasks];
    newArray[index].admin_notes = text;
    this.setState({ tasks: newArray });
  };

  handleStaffAction = (text, index) => {
    const newArray = [...this.state.tasks];
    newArray[index].staff_actions = text;
    this.setState({ tasks: newArray });
  };

  handleStaffNotes = (text, index) => {
    const newArray = [...this.state.tasks];
    newArray[index].staff_notes = text;
    this.setState({ tasks: newArray });
  };

  onchange = () => {
    this.props.onchange();
  };

  fetchTasks = async (service_id) => {
    let res = await Api.get(`admin/works/${this.props.workId}/tasks`, {
      params: { service_id: service_id },
    });

    const categories = res.data.data.categories;
    const status = res.data.data.taskStatus;
    const tasks = res.data.data.tasks;

    this.setState({
      categories: categories,
      status: status,
      tasks: tasks,
    });
  };

  renderTasks = (tasks) => {
    let tableData = tasks.map((task) => {
      return {
        ...task,
        checkbox: (
          <CheckBox
            disabled={false}
            value={this.state.checked}
            onValueChange={this.handleCheckBox}
          />
        ),
        status: (
          <View
            style={{
              backgroundColor: this.state.status.find((item) => item.id),
            }}
          >
            <PickerChoiceSelection
              items={this.state.status.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onValueChange={this.state.pickerValue}
            />
          </View>
        ),

        admin_actions: (
          <InputText
            value={task.admin_actions}
            onChangeText={(text) => this.handleAdminAction(text, task.task_id)}
            style={styles.borderStyle}
          />
        ),
        admin_notes: (
          <InputText
            value={task.admin_notes}
            onChangeText={(text) => this.handleAdminNotes(text)}
            style={styles.borderStyle}
          />
        ),

        staff_actions: (
          <InputText
            value={task.staff_actions}
            onChangeText={(text) => this.handleStaffAction(text)}
            style={styles.borderStyle}
          />
        ),
        staff_notes: (
          <InputText
            style={styles.borderStyle}
            value={task.staff_notes}
            onChangeText={(text) => this.handleStaffNotes(text)}
          />
        ),

        button: <Button onPress={() => this.addTask} title="save" />,
      };
    });

    return (
      <View style={styles.container}>
        <Table
          data={tableData}
          dataMap={{
            task: 'Task',
            checkbox: '#',
            status: 'Status',
            admin_actions: 'Ενεργειες Διαχ.',
            admin_notes: 'Σημειώσεις Διαχ.',
            staff_actions: 'Ενεργειες Τεχν.',
            staff_notes: 'Σημειώσεις Τεχν.',
            button: '',
          }}
          rowStyle={styles.rowStyle}
        />
      </View>
    );
  };

  render() {
    return (
      <View>
        <CardBlock>
          <TabView>
            {this.state.categories.map((category) => (
              <Tab title={category.category}>
                {this.renderTasks(
                  this.state.tasks.filter(
                    (item) => item.category_id === category.id
                  )
                )}
              </Tab>
            ))}
          </TabView>
        </CardBlock>
      </View>
    );
  }
}

export default WorkTasksForm;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  header: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },

  content: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: 25,
  },

  border: {
    borderWidth: 1,
  },

  rowStyle: {
    backgroundColor: '#a4bfeb',
    marginBottom: 15,
    textTransform: 'uppercase',
  },

  borderStyle: {
    width: 150,
    height: 80,
    marginBottom: 45,
  },
});
