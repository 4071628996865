import React from 'react';
import { StyleSheet, Text, View, TextInput } from 'react-native-web';
import Button from './Button';
import Api from '../api/Api';

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      repeatPassword: '',
      errorMessage: 'Passwords do not match',
    };
  }

  changePassword = () => {
    if (this.state.password === this.state.repeatPassword) {
      Api.post('/account', { password: this.state.password })
        .then((res) => {
          const data = res.data;
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert(this.state.errorMessage);
    }
  };

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.layout}>
          <Text style={styles.text}>New password</Text>
          <TextInput
            style={styles.input}
            placeholder="Enter new password"
            secureTextEntry={true}
            onChangeText={(text) => {
              this.setState({ password: text });
            }}
          />
          <Text style={styles.text}>New password</Text>
          <TextInput
            style={styles.input}
            placeholder="Repeat new password"
            secureTextEntry={true}
            onChangeText={(text) => {
              this.setState({ repeatPassword: text });
            }}
          />
          <Button onPress={this.changePassword}>
            <Text>Update Password</Text>
          </Button>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  input: {
    placeholderTextColor: 'black',
    backgroundColor: 'white',
    margin: '10px',
    padding: '15px',
    width: '300px',
  },
  layout: {
    padding: '80px',
    backgroundColor: '#bdbdbd',
    overflow: 'hidden',
  },

  text: {
    fontWeight: 'bold',
    marginRight: '210px',
    marginBottom: '5px',
  },
});
