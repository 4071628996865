import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';

export default class Button extends React.PureComponent {
  static propTypes = {
    onPress: PropTypes.func,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    styleText: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  };

  static defaultProps = {
    onPress: () => {},
    title: '',
    disabled: false,
  };

  render() {
    return (
      <TouchableOpacity
        activeOpacity={0.7}
        style={[styles.button, this.props.style]}
        onPress={this.props.onPress}
        disabled={this.props.disabled}
      >
        {this.props.children || (
          <Text style={[styles.text, this.props.styleText]}>
            {this.props.title}
          </Text>
        )}
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: 'grey',
    borderRadius: 5,
    padding: 15,
  },
  text: {
    color: '#fff',
  },
});
